import { Component, OnInit, ChangeDetectorRef, HostListener, ViewChild, Input } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ApiService } from "../../services/api.service";
import { defaultAnimations } from "../../shared/animations/default-animations";
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { FormModalComponent } from '../../shared/components/form-modal/form-modal.component';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppLoaderService } from '../../services/app-loader/app-loader.service';
import { Campain } from '../../helpers/interfaces';
import { dayDiff } from '../../helpers/func';

import { FormModalService } from '../../services/form-modal.service';

@Component({
  selector: 'app-back-office-admin-campaigns',
  templateUrl: './back-office-admin-campaigns.component.html',
  styleUrls: ['./back-office-admin-campaigns.component.scss'],
  animations: defaultAnimations
})
export class BackOfficeAdminCampaignsComponent implements OnInit {
  @Input() userId: any;
  @ViewChild('tableWrapper', { static: false }) tableWrapper;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  public readonly LAYOUT = {
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560,
  };
  public layout: number = this.LAYOUT.MD;
  customText: any = window['customText'][window['language']];
  campaign_type: { [name: string]: Campain } = this.customText.campaign_type;
  tva: number = window['tva'];

  counties: any = this.customText.counties;
  campaignType: any = this.customText.campaign_type;
  public items: any[];
  private currentComponentWidth: number;
  public pageNumber: number = 0;
  public per_page: number = 15;
  public totalElements: number = 0;

  ngxDatatableMessage = {
    selectedMessage: 'selected',
    totalMessage: 'total',
    emptyMessage: `Aucun résultat`
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    setTimeout(() => {
      if (this.table && this.table.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {

        if (this.items)
          this.items = [...this.items]
      }
    }, 200);
  }

  constructor(
    private apiService: ApiService,
    public changeDetectorRef: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loader: AppLoaderService,
    private formModalService: FormModalService
  ) {
    this.generateBreakPoints();
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  getDaysCount(dateBegin, dateEnd) {
    let result: number = Number(dayDiff(dateBegin, dateEnd));
    return result;
  }

  getTotal(selectedCampaign, dateBegin, dateEnd) {
    let dateA = new Date(dateBegin);
    let dateB = new Date(dateEnd);
    return Number(((this.campaign_type[selectedCampaign].price * this.getDaysCount(dateA, dateB)) / 100 * this.tva) + this.campaign_type[selectedCampaign].price * this.getDaysCount(dateA, dateB)).toFixed(2);
  }

  getTva(selectedCampaign, dateBegin, dateEnd) {
    return ((this.campaign_type[selectedCampaign].price * this.getDaysCount(dateBegin, dateEnd)) / 100 * this.tva).toFixed(2);
  }



  generateBreakPoints() {
    // Setting up breakpoint mechanism
    const breakpoints = Object.keys(this.LAYOUT).map(k => this.LAYOUT[k]);
    breakpoints.forEach((maxWidth, index) => {
      const minWidth = (index > 0) ? breakpoints[index - 1] : 0;
      this.breakpointObserver
        .observe([`(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`])
        .subscribe((state: BreakpointState) => {
          if (!state.matches) { return; }
          this.layout = maxWidth;

        });
    });
  }

  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.getUserReservations(this.pageNumber);
  }

  getUserReservations(pageNumber) {

    this.apiService.getEveryoneReservations(this.userId, pageNumber, this.per_page)
      .subscribe(
        reponse => {
          if (reponse && reponse['error'] == false) {
            this.totalElements = reponse['reservations']['totalCount'];
            this.items = reponse['reservations']['paginatedResults'];
          }
        },
        error => {
          //this.loading = false;
        },
        () => {
          //this.loading = false;
        }
      );
  }

  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition
    });
  }

  deleteItem(row) {

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: this.customText.admin_campaign_delete_reservation_confirmation.text,
        btnOk: this.customText.admin_campaign_delete_reservation_confirmation.btnOk,
        btnCancel: this.customText.admin_campaign_delete_reservation_confirmation.btnCancel
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.choice && result.choice == true) {
        this.apiService.adminDeleteCampaignReservation([row._id])
          .subscribe(
            reponse => {
              if (reponse && reponse['error'] == false && reponse['message']['deletedCount'] >= 1) {
                this.removeItem(row);
                this.totalElements--;
                this.snackBar.open(this.customText.snackBar_admin_campaign_deleted.text, "", {
                  duration: this.customText.snackBar_admin_campaign_deleted.duration,
                  verticalPosition: this.customText.snackBar_admin_campaign_deleted.verticalPosition,
                  horizontalPosition: this.customText.snackBar_admin_campaign_deleted.horizontalPosition
                });

              } else {
                this.displayErrorSnackbar("Error deleting campaign reservation");
              }
            },
            error => {
              this.displayErrorSnackbar(error);
            },
            () => {

            }
          )
      }
    });
  }

  /*
    removeItem(items, item) {
      let i = items.indexOf(item);
      items.splice(i, 1);
      if (items)
        items = [...items]
    }
    */


  removeItemFromArrayByComparaison(items, item) {

    let itemsList = [];
    for (let object of items) {
      let count = 0;
      for (let [key, value] of Object.entries(item)) {
        if (object[key] == value)
          count++;
      }
      if (!(Object.entries(item).length && (Object.entries(item).length == count) && count > 0)) {
        itemsList.push(object);
      }
    }
    return itemsList;
  }

  removeItem(row) {
    let i = this.items.indexOf(row);
    this.items.splice(i, 1);
    if (this.items)
      this.items = [...this.items]
  }

  updateItem(id, item) {
    this.items = this.items.map(i => {
      if (i._id === id) {
        return Object.assign({}, i, item);
      }
      return i;
    })
  }

  async onEditOrAdd(data: any = {}, isNew?) {

    let countiesArray: Array<any> = [];
    for (let [key, value] of Object.entries(this.counties)) {
      countiesArray.push({ value: key, label: value['label'] })
    }

    let campaignArray: Array<any> = [];
    for (let [key, value] of Object.entries(this.campaignType)) {
      campaignArray.push({ value: key, label: value['label'] })
    }

    let invalideDates = [];
    try {
      let resolveDates = await this.apiService.getDatesTaken().toPromise();
      if (resolveDates && resolveDates['dates']) {
        invalideDates = resolveDates['dates'];
        let itemToRemove = {
          campainDateBegin: data.campainDateBegin,
          campaignDateEnd: data.campaignDateEnd,
          // message: data.message
        };
        invalideDates = this.removeItemFromArrayByComparaison(invalideDates, itemToRemove);
      }
    } catch (error) {
    }

    const dialogRef: MatDialogRef<any> = this.dialog.open(FormModalComponent, {
      //maxWidth: '650px',
      minWidth: '320px',
      data: {
        title: "Update Campagne",
        subtitle: "",
        btnCancel: "Quit",
        btnOk: "Save",
        inputs: [
          {
            label: "Département",
            name: "campaignCounty",
            type: "select",
            placeholder: "Département",
            value: data.campaignCounty,
            options: countiesArray,
            validators: [Validators.required], error: "requis"
          },
          {
            label: "Support de campagne",
            name: "campaignSupport",
            type: "select",
            placeholder: "Campagne support",
            value: data.campaignSupport,
            options: campaignArray,
            validators: [Validators.required], error: "requis"
          },
          {
            label: "Dates",
            name: "campaignDates",
            type: "dateRange",
            placeholder: "Campagne dates",
            value: {
              startDate: moment(data.campainDateBegin),
              endDate: moment(data.campaignDateEnd)
            },
            invalideDates: invalideDates,
            invalideDatesLinkedTo: { input: "campaignSupport", activeWhen: "open_app" },
            options: campaignArray,
            validators: [Validators.required], error: "requis"
          },
          {
            label: "Campagne validé",
            name: "validated",
            type: "slider",
            //linkedTo: "campaignDates",
            disableOtherItemsOnState: true,
            alertOnChange: { message: this.customText.admin_reservation_management_validate_message, btnOk: "Ok", onlyWhenChecked: true, linkedTo: "campaignSupport", displayIf: "open_app" },
            placeholder: "Campagne validé",
            value: data.validated,
            validators: [Validators.required],
            error: "requis"
          },
        ]
      }
    });


    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.apiService.adminEditCampaign(data._id, result.campaignSupport, result.campaignCounty,
          result.campaignDates.startDate.toDate(), result.campaignDates.endDate.toDate(), result.validated)
          .subscribe(
            reponse => {
              if (reponse && reponse['error'] == false && reponse['campaign']) {
                this.updateItem(data._id, reponse['campaign']);
                this.snackBar.open(this.customText.snackBar_reservation_edited.text, "", {
                  duration: this.customText.snackBar_reservation_edited.duration,
                  verticalPosition: this.customText.snackBar_reservation_edited.verticalPosition,
                  horizontalPosition: this.customText.snackBar_reservation_edited.horizontalPosition
                });

              } else {
                this.displayErrorSnackbar("Error reservating");
              }
            },
            error => {
              this.loader.close();
              this.displayErrorSnackbar(error);
            },
            () => {
              this.loader.close();
            }
          )
      }
    });

  }

  addCampaignTracking(row) {
    row._id = row.ownerId;
    row.lastName = row.user.lastName;
    this.formModalService.editCampaignTracking(row, true);
  }

}

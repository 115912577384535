import { Component, OnInit, Input, OnChanges, SimpleChange, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-download-stats',
  templateUrl: './download-stats.component.html',
  styleUrls: ['./download-stats.component.scss']
})
export class DownloadStatsComponent implements OnInit {
  @Input() downloadTotal: number = 0;
  @Input() downloadData: any = [];
  @Input() period: any;
  @Input() barColors: any;
  @ViewChild('barCanvasPageViews', { static: false }) canvas: ElementRef;

  downloadAverage: number = 0;
  downloadCount: number = 0;
  
  constructor() { }

  sharedChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1.3,
    legend: {
      display: false,
      position: 'bottom'
    }
  };
  /*
  chartColors: Array<any> = [{
    backgroundColor: '#00CDAE',
    borderColor: '#3f51b5',
    pointBackgroundColor: '#3f51b5',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  }];
*/
  barChartType = 'bar';
  barChartData: any[] = [{
    data: [],
    label: '',
    borderWidth: 0,
    backgroundColor: 'rgba(0,205,174,1.0)',
    borderColor: '#3f51b5',
    pointBackgroundColor: '#3f51b5',
    pointBorderColor: '#fff',
    hoverBackgroundColor: 'rgba(0,205,174,0.4)',
    hoverBorderColor: 'rgba(148,159,177,0.8)'
  }];
  barChartOptions: any = Object.assign({
    scaleShowVerticalLines: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0.02)',
          zeroLineColor: 'rgba(0,0,0,0.02)'
        },
        ticks: {
          maxTicksLimit: 5,
          fontSize: 10,
          fontColor: "#DBDBDC",
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0
        },
        type: 'time',
        time: {
          minUnit: 'day'
        },
        distribution: 'linear'
      }],
      yAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0.05)',
          zeroLineColor: 'rgba(0,0,0,0.02)'
        },
        position: 'left',
        ticks: {
          beginAtZero: true,
          suggestedMax: 9,
          maxTicksLimit: 5
        }
      }]
    }
  }, this.sharedChartOptions);


  ngOnInit() {  

  }

  ngAfterViewInit(): void {
    this.refresh();
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if (changes['downloadData'] && this.downloadData) {
      this.refresh();
    }
  }

  refresh(){
    this.downloadAverage = this.getDownloadAverage();
    this.downloadCount = this.getDownloadCount();
    this.setDownloadTab(this.repackData(this.downloadData));
  }

  setDownloadTab(data){
    
    if(data){
      let backGroundColor: any = '#1EE2C6';
    
      if(this.canvas && this.barColors && this.barColors.length){
        backGroundColor = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 100);
        this.barColors.forEach((color, index)  => {
          backGroundColor.addColorStop(index, color);
        });
      }

      this.barChartData[0].data = [];
      this.barChartData[0].backgroundColor = backGroundColor;
      Object.entries(data).forEach(([key, value]) => {
        this.barChartData[0].data.push({ t: key, y: value });
      });
    }
  }

  repackData(data){
    let objects = {}
    data.forEach( (element, index) => {
      if( !(index == data.length - 1 && element.counter == 0) )
        objects[element.collected_at] = objects[element.collected_at] ? objects[element.collected_at] + element.counter : element.counter;
    });
    return objects;
  }

  getDownloadCount() {
    if(!this.downloadData)
      return 0;
    let total = 0;
    this.downloadData.forEach(element => {
      total += element.counter;
    });
    return total;
  }

  getDownloadAverage() {
    if( !(this.downloadData  && this.period && this.period.days_count) )
      return 0;
    return Math.round(this.getDownloadCount() / Math.abs(this.period.days_count));
  }
  /*
  $time_precedent = strtotime("-2 days", $dates['time_debut']);
  $res["nb_downloads_precedents"] = $this->api->getDownloadsTotal("totalByPeriod", $time_precedent, $dates['time_debut'], $platform);
  $res["tendance"] = 0;
  if ($res["nb_downloads_precedents"] > 0) $res["tendance"] = round((($res["nb_downloads_par_jour"] - $res["nb_downloads_precedents"]) / $res["nb_downloads_precedents"]) * 100);
  elseif ($res["nb_par_jour"] > 0) $res["tendance"] = 100;
  */

  getDownloadTrend(){
    //return Math.round((($res["nb_downloads_par_jour"] - $res["nb_downloads_precedents"]) / $res["nb_downloads_precedents"]) * 100);
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { AppLoaderService } from '../../../../services/app-loader/app-loader.service';
import { AlertModalComponent } from '../../../../shared/components/alert-modal/alert-modal.component';
import { MatSnackBar, MatDialog } from '@angular/material';

@Component({
  selector: 'app-publish-upload',
  templateUrl: './publish-upload.component.html',
  styleUrls: ['./publish-upload.component.scss']
})
export class PublishUploadComponent implements OnInit {
  @Input() countySelected: string;
  @Input() dateBegin: Date;
  @Input() dateEnd: Date;
  @Output() uploadEvent = new EventEmitter<any>();
  files: any = [];
  maxUpload: number = 10;
  customText: any = window['customText'][window['language']];

  constructor(
    private apiService: ApiService, 
    private loader: AppLoaderService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
    ) { }

  ngOnInit() {
  }

  addFile(event) {


    let FILES;
    if (event.target)
      FILES = (event.target as HTMLInputElement).files;
    else
      FILES = event;

    if(this.files.length + FILES.length >= this.maxUpload){
      this.dialog.open(AlertModalComponent, {
        maxWidth: '350px',
        data: {
          message: `Un maximum de ${this.maxUpload} fichiers est permis`, btnOk: "Ok",
          icon: { maticon: "warning", style: { 'color': 'indianred', 'height': '34px', 'width': '34px', 'font-size': '34px' } }
        }
      });
      return;
    }

    if (FILES.length)
      this.files.push(...FILES);
    else
      this.files.push(FILES);

  }

  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition
    });
  }

  upload() {
    var filesForm = new FormData();
    this.files.forEach(file => {
      filesForm.append("files", file);
    });

    filesForm.append("countySelected", this.countySelected);
    filesForm.append("dateBegin", this.dateBegin.toUTCString());
    filesForm.append("dateEnd", this.dateEnd.toUTCString());

    this.loader.open("Veuillez patienter");

    this.apiService.upload(filesForm)
      .subscribe(
        reponse => {
          this.uploadEvent.emit(reponse);
          this.files = [];
          this.snackBar.open(this.customText.snackBar_files_uploaded.text, "", {
            duration: this.customText.snackBar_files_uploaded.duration,
            verticalPosition: this.customText.snackBar_files_uploaded.verticalPosition,
            horizontalPosition: this.customText.snackBar_files_uploaded.horizontalPosition
          });
        },
        error => {
          this.files = [];
          this.loader.close();
        },
        () => {
          this.loader.close();
        }
      );


  }
}

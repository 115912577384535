import { Component, OnInit, ViewChild } from '@angular/core';
import { BackOfficePublishUploadHistoryComponent } from '../back-office-publish-upload-history/back-office-publish-upload-history.component';

@Component({
  selector: 'app-back-office-publish-container',
  templateUrl: './back-office-publish-container.component.html',
  styleUrls: ['./back-office-publish-container.component.scss']
})
export class BackOfficePublishContainerComponent implements OnInit {
  @ViewChild(BackOfficePublishUploadHistoryComponent, { static: false }) public uploadHistory: BackOfficePublishUploadHistoryComponent;
  customText: any = window['customText'][window['language']];
  counties: any = this.customText.counties;
  countySelected: String = "maba";
  dateBegin: Date;
  dateEnd: Date;

  constructor() { }

  ngOnInit() {
  }

  onCountieChangedEvent(checkResult) {
    this.countySelected = checkResult;
  }
  onDateRangeChange(event){
    if(event){
      this.dateBegin = event.startDate.toDate();
      this.dateEnd = event.endDate.toDate();
    }
  }

}

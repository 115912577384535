import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment-timezone';
import * as localization from 'moment/locale/fr';

import { DaterangepickerComponent } from 'ngx-daterangepicker-material';

moment.locale('fr', localization);
moment.tz.setDefault("UTC");

@Component({
  selector: 'app-dates-range',
  templateUrl: './dates-range.component.html',
  styleUrls: ['./dates-range.component.scss']
})
export class DatesRangeComponent implements OnInit {

  @ViewChild(DaterangepickerComponent, { static: true }) datePicker: DaterangepickerComponent;
  @Input() datesInvalid;
  modifiedInvalidDates: any;
  @Output() dateRangeModified = new EventEmitter<any>();

  locale = {
    applyLabel: 'Appliquer',
    customRangeLabel: ' - ',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek(),
  }

  constructor() {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.datesInvalid) {
      this.datePicker.setStartDate(moment().startOf('day'));
      this.datePicker.setEndDate(moment().endOf('day'));
      this.dateRangeModified.emit({ startDate: null, endDate: null });
      this.datePicker.updateView();
    }
  }

  choosedDate(event) {
    this.dateRangeModified.emit(event);
  }

  checkIfMomentInsideDateArray(dateArray, moment) {
    if (dateArray && dateArray) {

      for (let dates of dateArray) {
        //let dateA = dates.campainDateBegin.slice(0,10);
        //let dateB = dates.campaignDateEnd.slice(0,10);
        let dateA = dates.campainDateBegin;
        let dateB = dates.campaignDateEnd;

        let check = moment.isBetween(dateA, dateB, null, '[]');
        if (check == true)
          return true;
      }
    }
    return false;
  }

  isInvalidDate = (m: moment.Moment) => {
    let isInvalid = this.checkIfMomentInsideDateArray(this.datesInvalid, m);
    return (!moment(new Date()).isSameOrBefore(m, "day") || isInvalid);
  }


}

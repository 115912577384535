import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-multi-stats-board',
  templateUrl: './multi-stats-board.component.html',
  styleUrls: ['./multi-stats-board.component.scss']
})
export class MultiStatsBoardComponent implements OnInit {
  @Input() StatsList: Array<any>;
  
  constructor() { }

  ngOnInit() {
  }

}

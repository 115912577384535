import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';

import { defaultAnimations } from "../../shared/animations/default-animations";
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog, MatSnackBar, MatInputModule, MatSelectModule, MatFormFieldModule } from '@angular/material';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormsModule } from '@angular/forms';

//Services
import { ApiService } from "../../services/api.service";
import { FormModalService } from '../../services/form-modal.service';
import { SelectModalResponsablesComponent } from 'src/app/shared/components/select/select-modal-responsables.component';
import { error } from 'console';

@Component({
  selector: 'app-back-office-admin-users',
  templateUrl: './back-office-admin-users.component.html',
  styleUrls: ['./back-office-admin-users.component.scss'],
  animations: defaultAnimations,
})

export class BackOfficeAdminUsersComponent implements OnInit {

  public readonly LAYOUT = {
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560,
  };
  public layout: number = this.LAYOUT.MD;

  customText: any = window['customText'][window['language']];
  public items: any[];
  private currentComponentWidth;

  @ViewChild('tableWrapper', { static: false }) tableWrapper;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  public filteredData: any[];
  defaultItems: any[];
  valeurLocalStorage: string;
  debutLogin: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    setTimeout(() => {
      if (this.table && this.table.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {

        if (this.items)
          this.items = [...this.items]
      }
    }, 200);
  }

  ngxDatatableMessage = {
    selectedMessage: 'selected',
    totalMessage: 'total',
    emptyMessage: `Aucun résultat`
  }

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    public breakpointObserver: BreakpointObserver,
    private formModalService: FormModalService,

  ) {
    this.generateBreakPoints();
  }

  generateBreakPoints() {
    // Setting up breakpoint mechanism
    const breakpoints = Object.keys(this.LAYOUT).map(k => this.LAYOUT[k]);
    breakpoints.forEach((maxWidth, index) => {
      const minWidth = (index > 0) ? breakpoints[index - 1] : 0;
      this.breakpointObserver
        .observe([`(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`])
        .subscribe((state: BreakpointState) => {
          if (!state.matches) { return; }
          this.layout = maxWidth;

        });
    });
  }
  ngOnInit() {
    this.getUsers();
    this.valeurLocalStorage = localStorage.getItem('loginInfo');
    if (this.valeurLocalStorage) {
      const objetLocalStorage = JSON.parse(this.valeurLocalStorage);
      const login = objetLocalStorage.login;
      const indexArobase = login.indexOf('@');
      this.debutLogin = login.substring(0, indexArobase);
    } else {
      console.log('La valeur n\'existe pas dans le local storage ou est null.');
    }

  }

  search(value: string) {
    console.log("value: ", value);
    if (!value) {
      this.items = this.defaultItems;
    } else {
      this.items = this.items.filter((x) => x.name.trim().toLowerCase().includes(value.trim().toLowerCase()))
    }
  }
  /*
    ngAfterViewChecked() {
        
      // Check if the table size has changed,
      if (this.table && this.table.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {
        this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
        this.table.recalculate();
        this.table.recalculateColumns();
   
        this.changeDetectorRef.detectChanges();
        this.changeDetectorRef.markForCheck();
        
      }
    }
  */
  getUsers() {

    this.apiService.adminGetUsers()
      .subscribe(
        reponse => {
          if (reponse && reponse['error'] == false) {
            this.items = reponse['users'];
            this.defaultItems = reponse['users']
          }
        },
        error => {
          //this.loading = false;
        },
        () => {
          //this.loading = false;
        }
      );
  }

  editUser(data: any = {}, isNew?) {
    /*
    console.log("la", this.items)
    var pf = this.items;
   
    pf.push({"_id":"5e3f33a125b63a46691ec738","approved":true,"firstName":"poom","lastName":"rebeccaf","email":"pasqualini.olivier@gmail.com","company":"bla","position":"rhum","phone":"121212","lastLoginDate":"2020-02-21T14:33:15.698Z","role":"user"}) 
  
     if (this.items)
     this.items = [...this.items]
  
    console.log( this.items)
  */
    this.formModalService.editUser(this, 'items', data);

    /*
        const dialogRef: MatDialogRef<any> = this.dialog.open(FormModalComponent, {
          //maxWidth: '650px',
          minWidth: '320px',
          data: {
            title: "Update utilisateur",
            subtitle: "",
            btnCancel: "Quit",
            btnOk: "Save",
            inputs: [
              [ 
                { label: "Nom", name: "lastName", type: "input", placeholder: "Nom", value: data.lastName, validators: [ Validators.required ] , error: "requis" },  
                { label: "Prénom", name : "firstName", type: "input", placeholder: "Prénom", value: data.firstName, validators: [ Validators.required ], error: "requis" }
              ], 
              { label: "Entreprise", name : "company", type: "input", placeholder: "Entreprise", value: data.company, validators: [ Validators.required ], error: "requis" }, 
              { label: "Fonction", name : "position", type: "input", placeholder: "Fonction", value: data.position, validators: [ Validators.required ], error: "requis" }, 
              [
                { label: "N° de téléphone", name : "phone", type: "input", placeholder: "N° de téléphone", value: data.phone, validators: [ Validators.required ], error: "requis" }, 
                { label: "Utilisateur activé", name : "approved", type: "slider", placeholder: "Compte activé", value: data.approved, validators: [ Validators.required ], error: "requis" }, 
              ]
            ]
          }
        });
        
        dialogRef.afterClosed().subscribe(result => {
      
          if (result) {
            this.apiService.adminEditUser(data._id ,result)
            .subscribe(
              reponse => {
                if (reponse && reponse['error'] == false) {
                  this.updateItem(data._id, reponse['user']);
                  this.snackBar.open(this.customText.snackBar_admin_user_edited.text, "", {
                    duration: this.customText.snackBar_admin_user_edited.duration,
                    verticalPosition: this.customText.snackBar_admin_user_edited.verticalPosition,
                    horizontalPosition: this.customText.snackBar_admin_user_edited.horizontalPosition
                  });
    
                } else {
                  this.displayErrorSnackbar("Error editing user");
                }
              },
              error => {
                this.displayErrorSnackbar(error);
              },
              () => {
    
              }
            )
          
          }
        });
        */
  }

  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition
    });
  }

  deleteItem(row) {

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: this.customText.admin_users_delete_user_confirmation.text,
        btnOk: this.customText.admin_users_delete_user_confirmation.btnOk,
        btnCancel: this.customText.admin_users_delete_user_confirmation.btnCancel
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.choice && result.choice == true) {
        this.apiService.adminDeleteUser(row._id)
          .subscribe(
            reponse => {
              if (reponse && reponse['error'] == false && reponse['message']['deletedCount'] >= 1) {
                this.removeItem(row);
                this.snackBar.open(this.customText.snackBar_admin_user_deleted.text, "", {
                  duration: this.customText.snackBar_admin_user_deleted.duration,
                  verticalPosition: this.customText.snackBar_admin_user_deleted.verticalPosition,
                  horizontalPosition: this.customText.snackBar_admin_user_deleted.horizontalPosition
                });

              } else {
                this.displayErrorSnackbar("Error deleting user");
              }
            },
            error => {
              this.displayErrorSnackbar(error);
            },
            () => {

            }
          )
      }
    });

  }

  editRoleUserToResponsable(row: any) {
    let message: string;
    let roleToUpdate: string;

    if (row.role === "user") {
      message = this.customText.admin_users_edit_role_to_responsable_confirmation.text;
      roleToUpdate = "responsable";
    } else if (row.role === "responsable") {
      message = this.customText.admin_users_edit_role_to_user_confirmation.text;
      roleToUpdate = "user";
    }

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: message,
        btnOk: this.customText.admin_users_edit_role_to_responsable_confirmation.btnOk,
        btnCancel: this.customText.admin_users_edit_role_to_responsable_confirmation.btnCancel
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.choice && result.choice == true) {
        this.apiService.adminEditRoleUserToResponsable(row._id, { role: roleToUpdate }).subscribe(
          reponse => {
            // if (reponse && reponse['error'] == false && reponse['message']) {
            if (reponse && reponse['error'] == false) {
              this.snackBar.open(this.customText.snackBar_admin_user_edited_to_responsable.text, "", {
                duration: this.customText.snackBar_admin_user_edited_to_responsable.duration,
                verticalPosition: this.customText.snackBar_admin_user_edited_to_responsable.verticalPosition,
                horizontalPosition: this.customText.snackBar_admin_user_edited_to_responsable.horizontalPosition
              });

            } else {
              this.displayErrorSnackbar("Error editing user");
            }
          },
          error => {
            this.displayErrorSnackbar(error);
          },
          () => {

          }
        )
      }
    })

  }

  editAssignResponsable(row: any) {
    this.dialog.open(SelectModalResponsablesComponent, {
      minWidth: '320px',
      data: { rowData: row }
    });
    // dialogRef.afterClosed().subscribe(result => {

    //   if (result && result.choice && result.choice == true) {

    //   } else {
    //     this.displayErrorSnackbar("Error editing user");
    //   }
    // },
    //   error => {
    //     this.displayErrorSnackbar(error);
    //   },
    //   () => {

    //   }
    // )
  }


  removeItem(row) {
    let i = this.items.indexOf(row);
    this.items.splice(i, 1);
    if (this.items)
      this.items = [...this.items]
  }

  updateItem(id, item) {
    this.items = this.items.map(i => {
      if (i._id === id) {
        return Object.assign({}, i, item);
      }
      return i;
    })
  }

  addCampaignTracking(row) {
    this.formModalService.editCampaignTracking(row, true);
  }
} 
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service'
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';

/**
 * @title Basic select
 */

interface Responsable {
    role: string;
    approved: boolean;
    lastLoginDate: string;
    firstName: string;
    lastName: string;
    company: string;
    position: string;
    phone: string;
    responsableId?: string;
    _id: string;
}

@Component({
    selector: 'app-select-modal-responsables',
    templateUrl: './select-modal-responsables.component.html',
    styleUrls: ['./select-modal-responsables.component.scss']
})

export class SelectModalResponsablesComponent implements OnInit {
    responsables: Responsable[] = [];
    selectedResponsable: Responsable;
    selectedResponsableId: string;

    customText: any = window['customText'][window['language']];


    constructor(
        private responsableService: ApiService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private dialogData: any
    ) { }

    ngOnInit() {
        this.loadResponsables();
        const rowData = this.dialogData.rowData;
        console.log('Row data received in dialog:', rowData);
    }

    loadResponsables() {
        this.responsableService.getResponsables().subscribe((data: any) => {
            if ('user' in data) {
                this.responsables = data.user as Responsable[];
            } else {
                console.error("La propriété 'user' est absente dans les données reçues.");
            }
        });
    }

    selectResponsable() {
        if (this.selectedResponsableId) {
            this.selectedResponsable = this.responsables.find(responsable => responsable._id === this.selectedResponsableId);
            this.responsableService.adminAssignResponsableToUser(this.dialogData.rowData._id, this.selectedResponsable._id).subscribe(
                reponse => {
                    console.log("reponse: ", reponse);
                    if (reponse && reponse['error'] == false) {
                        this.snackBar.open(this.customText.snackBar_admin_responsable_assigned.text, "", {
                            duration: this.customText.snackBar_admin_responsable_assigned.duration,
                            verticalPosition: this.customText.snackBar_admin_responsable_assigned.verticalPosition,
                            horizontalPosition: this.customText.snackBar_admin_responsable_assigned.horizontalPosition
                        });
                        this.dialog.closeAll()
                        //   if (result && result.choice && result.choice == true) {

                        //   } else {
                        //     this.displayErrorSnackbar("Error editing user");
                        //   }
                        // },
                        //   error => {
                        //     this.displayErrorSnackbar(error);
                        //   },
                        //   () => {

                        //   }
                        // )
                    }

                    else {
                        console.log("Error assignation responsable");
                    }
                },
                error => {
                    console.log(error);
                },
                () => {

                }
            )
        } else {
            console.error("Aucun responsable sélectionné.");
        }
    }
}
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ApiService } from "../../services/api.service";
import { AppLoaderService } from '../../services/app-loader/app-loader.service';

@Component({
  selector: 'app-back-office-admin-statistics',
  templateUrl: './back-office-admin-statistics.component.html',
  styleUrls: ['./back-office-admin-statistics.component.scss']
})
export class BackOfficeAdminStatisticsComponent implements OnInit {

  customForm: any = null;
  customText: any = window['customText'][window['language']];
  constructor(
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private loader: AppLoaderService
  ) { }

  onSave(form) {
    console.log("form: ", form);

    this.apiService.updateExtraStatistics(form)
      .subscribe(
        reponse => {
          if (reponse && reponse['error'] == false) {
            this.snackBar.open(this.customText.snackBar_admin_dashboard_extra_save.text, "", {
              duration: this.customText.snackBar_admin_dashboard_extra_save.duration,
              verticalPosition: this.customText.snackBar_admin_dashboard_extra_save.verticalPosition,
              horizontalPosition: this.customText.snackBar_admin_dashboard_extra_save.horizontalPosition
            });

          } else {
            this.displayErrorSnackbar("Une erreur s'est produite");
          }
        },
        error => {
          this.displayErrorSnackbar(error);
        },
        () => {
        }
      )

  }

  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition
    });
  }

  fillCustomForm(inputDatas: any = null) {
    console.log("inputDatas: ", inputDatas);
    this.customForm = {
      data: {
        btnOk: "Enregistrer",
        boxes: [
          {
            title: "Autre",
            inputs: [
              {
                subtitle: "Inscrits à la newsletter",
                data: [{
                  label: "nombre",
                  name: "newsletter",
                  type: "input",
                  inputType: "number",
                  value: (inputDatas && inputDatas.newsletter) ? inputDatas.newsletter : 0,
                  validators: [Validators.required], error: "requis"
                },]
              }
            ]

          },
          {
            title: "Audience",
            inputs: [
              {
                subtitle: "Age",
                data: [
                  {
                    label: "18-24",
                    name: "aud_18_24",
                    type: "input",
                    inputType: "number",
                    min: 0,
                    max: 100,
                    value: (inputDatas && inputDatas.aud_18_24) ? inputDatas.aud_18_24 : 0,
                    validators: [Validators.required, Validators.min(0), Validators.max(100)], error: "requis"
                  },
                  {
                    label: "25-34",
                    name: "aud_25_34",
                    type: "input",
                    inputType: "number",
                    min: 0,
                    max: 100,
                    value: (inputDatas && inputDatas.aud_25_34) ? inputDatas.aud_25_34 : 0,
                    validators: [Validators.required, Validators.min(0), Validators.max(100)], error: "requis"
                  },
                  {
                    label: "35-44",
                    name: "aud_35_44",
                    type: "input",
                    inputType: "number",
                    min: 0,
                    max: 100,
                    value: (inputDatas && inputDatas.aud_35_44) ? inputDatas.aud_35_44 : 0,
                    validators: [Validators.required, Validators.min(0), Validators.max(100)], error: "requis"
                  },
                  {
                    label: "45-54",
                    name: "aud_45_54",
                    type: "input",
                    inputType: "number",
                    min: 0,
                    max: 100,
                    value: (inputDatas && inputDatas.aud_45_54) ? inputDatas.aud_45_54 : 0,
                    validators: [Validators.required, Validators.min(0), Validators.max(100)], error: "requis"
                  },
                  {
                    label: "55+",
                    name: "aud_55_plus",
                    type: "input",
                    inputType: "number",
                    min: 0,
                    max: 100,
                    value: (inputDatas && inputDatas.aud_55_plus) ? inputDatas.aud_55_plus : 0,
                    validators: [Validators.required, Validators.min(0), Validators.max(100)], error: "requis"
                  }
                ]
              },
              {
                subtitle: "Sexe",
                data: [
                  {
                    label: "Masculin",
                    name: "male",
                    type: "input",
                    inputType: "number",
                    min: 0,
                    max: 100,
                    value: (inputDatas && inputDatas.male) ? inputDatas.male : 0,
                    validators: [Validators.required, Validators.min(0), Validators.max(100)], error: "requis"
                  },
                  {
                    label: "Féminin",
                    name: "female",
                    type: "input",
                    inputType: "number",
                    min: 0,
                    max: 100,
                    value: (inputDatas && inputDatas.female) ? inputDatas.female : 0,
                    validators: [Validators.required, Validators.min(0), Validators.max(100)], error: "requis"
                  },
                ]
              },
              {
                subtitle: "Notifications",
                data: [
                  {
                    label: "iOS",
                    name: "ios_notifications",
                    type: "input",
                    inputType: "number",
                    min: 0,
                    value: (inputDatas && inputDatas.ios_notifications) ? inputDatas.ios_notifications : 0,
                    validators: [Validators.required, Validators.min(0)],
                    error: "requis"
                  },
                  {
                    label: "Android",
                    name: "android_notifications",
                    type: "input",
                    inputType: "number",
                    min: 0,
                    value: (inputDatas && inputDatas.android_notifications) ? inputDatas.android_notifications : 0,
                    validators: [Validators.required, Validators.min(0)],
                    error: "requis"
                  }
                ]
              }
            ]
          }
        ]

      }
    }
  }

  ngOnInit() {
    this.loader.open("Veuillez patienter");
    this.apiService.getExtraStatistics()
      .subscribe(
        reponse => {
          if (reponse && reponse['error'] == false && reponse['statistics']) {
          } else {
            this.displayErrorSnackbar("Aucune donnée trouvée");
          }
          this.fillCustomForm(reponse['statistics']);
        },
        error => {
          this.loader.close();
          this.fillCustomForm();
          this.displayErrorSnackbar(error);
        },
        () => {
          this.loader.close();
        }
      )
  }
}
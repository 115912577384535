import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';

import { defaultAnimations } from "../../shared/animations/default-animations";
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MapModalComponent } from '../../shared/components/map-modal/map-modal.component';

//Services
import { ApiService } from "../../services/api.service";
import { FormModalService } from '../../services/form-modal.service';
import { AppLoaderService } from '../../services/app-loader/app-loader.service';

@Component({
  selector: 'app-back-office-admin-catalog',
  templateUrl: './back-office-admin-catalog.component.html',
  styleUrls: ['./back-office-admin-catalog.component.scss'],
  animations: defaultAnimations
})
export class BackOfficeAdminCatalogComponent implements OnInit {
  public readonly LAYOUT = {
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560,
  };
  public layout: number = this.LAYOUT.MD;

  customText: any = window['customText'][window['language']];
  public items: any[];
  private currentComponentWidth;

  @ViewChild('tableWrapper', { static: false }) tableWrapper;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    setTimeout(() => {
      if (this.table && this.table.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {

        if (this.items)
          this.items = [...this.items]
      }
    }, 200);
  }

  ngxDatatableMessage = {
    selectedMessage: 'selected',
    totalMessage: 'total',
    emptyMessage: `Aucun résultat`
  }

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    public breakpointObserver: BreakpointObserver,
    private formModalService: FormModalService,
    private loader: AppLoaderService
  ) {
    this.generateBreakPoints();
  }

  generateBreakPoints() {
    // Setting up breakpoint mechanism
    const breakpoints = Object.keys(this.LAYOUT).map(k => this.LAYOUT[k]);
    breakpoints.forEach((maxWidth, index) => {
      const minWidth = (index > 0) ? breakpoints[index - 1] : 0;
      this.breakpointObserver
        .observe([`(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`])
        .subscribe((state: BreakpointState) => {
          if (!state.matches) { return; }
          this.layout = maxWidth;

        });
    });
  }
  ngOnInit() {
    this.getOpenedCatalogPositionListSummary();
  }
  /*
    ngAfterViewChecked() {
        
      // Check if the table size has changed,
      if (this.table && this.table.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {
        this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
        this.table.recalculate();
        this.table.recalculateColumns();
  
        this.changeDetectorRef.detectChanges();
        this.changeDetectorRef.markForCheck();
        
      }
    }
  */
 getOpenedCatalogPositionListSummary() {
    this.loader.open("Veuillez patienter");
    this.apiService.getOpenedCatalogPositionListSummary()
      .subscribe(
        reponse => {
          if (reponse && reponse['error'] == false) {
            this.items = reponse['catalog'];
          }
        },
        error => {
          this.loader.close();
          //this.loading = false;
        },
        () => {
          this.loader.close();
          //this.loading = false;
        }
      );
  }



  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition
    });
  }

  deleteItem(row) {

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: this.customText.admin_campaigntracking_delete_catalog_confirmation.text,
        btnOk: this.customText.admin_campaigntracking_delete_catalog_confirmation.btnOk,
        btnCancel: this.customText.admin_campaigntracking_delete_catalog_confirmation.btnCancel
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.choice && result.choice == true) {
        this.apiService.adminDeleteCatalogs([row._id])
          .subscribe(
            reponse => {
              if (reponse && reponse['error'] == false && reponse['message']['deletedCount'] >= 1) {
                this.removeItem(row);
                this.snackBar.open(this.customText.snackBar_admin_catalog_deleted.text, "", {
                  duration: this.customText.snackBar_admin_catalog_deleted.duration,
                  verticalPosition: this.customText.snackBar_admin_catalog_deleted.verticalPosition,
                  horizontalPosition: this.customText.snackBar_admin_catalog_deleted.horizontalPosition
                });

              } else {
                this.displayErrorSnackbar("Error deleting catalogue");
              }
            },
            error => {
              this.displayErrorSnackbar(error);
            },
            () => {

            }
          )
      }
    });

  }

  removeItem(row) {
    let i = this.items.indexOf(row);
    this.items.splice(i, 1);
    if (this.items)
      this.items = [...this.items]
  }

  updateItem(id, item) {
    this.items = this.items.map(i => {
      if(i._id === id) {
        return Object.assign({}, i, item);
      }
      return i;
    })
  }

  viewOnMap(row){
    this.dialog.open(MapModalComponent, {
      maxWidth: '100vw',
      width: '750px',
      data: {
        catalogId: row._id
      }
    });

    /*
    this.apiService.getOpenedCatalogPositions(row._id)
    .subscribe(
      reponse => {
        if (reponse && reponse['error'] == false) {
          this.dialog.open(MapModalComponent, {
            maxWidth: '100vw',
            width: '750px',
            data: {
              points: reponse['catalogOpenedPosition']
            }
          });
        }
      },
      error => {
        //this.loading = false;
      },
      () => {
        //this.loading = false;
      }
    );
  */



  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mini-stats',
  templateUrl: './mini-stats.component.html',
  styleUrls: ['./mini-stats.component.scss']
})
export class MiniStatsComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() color: string;
  @Input() value: number;
  @Input() type: string;
  constructor() { }

  ngOnInit() {
  }

  isExtSvg(name: string){
    let ext = this.getFileExtension(name);
    return (ext && ext == "svg") || false;
  }

  getFileExtension(file: string) {
    var regexp = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    var extension = file.match(regexp);
    return extension && extension[1];
  }

}

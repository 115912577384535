import { Component, OnInit, ChangeDetectorRef, HostListener, ViewChild, Input } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { defaultAnimations } from "../../shared/animations/default-animations";
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { FormModalComponent } from '../../shared/components/form-modal/form-modal.component';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { AppLoaderService } from '../../services/app-loader/app-loader.service';
import { Campain } from '../../helpers/interfaces';
import { dayDiff } from '../../helpers/func';

import { ApiService } from "../../services/api.service";
import { FormModalService } from '../../services/form-modal.service';

import { NgxDatatableHelper } from '../../helpers/ngxDatatableHelper';
@Component({
  selector: 'app-back-office-admin-campaigns-tracking',
  templateUrl: './back-office-admin-campaigns-tracking.component.html',
  styleUrls: ['./back-office-admin-campaigns-tracking.component.scss'],
  animations: defaultAnimations
})
export class BackOfficeAdminCampaignsTrackingComponent extends NgxDatatableHelper implements OnInit {
  @Input() userId: any;
  @ViewChild('tableWrapper', { static: false }) tableWrapper;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  public readonly LAYOUT = {
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560,
  };
  public layout: number = this.LAYOUT.MD;
  customText: any = window['customText'][window['language']];
  campaign_type: { [name: string]: Campain }  = this.customText.campaign_type;
  tva: number = window['tva'];

  counties: any = this.customText.counties;
  campaignType: any = this.customText.campaign_type;
  public items: any[];
  private currentComponentWidth: number;
  public pageNumber: number = 0;
  public per_page:number = 15;
  public totalElements: number = 0;

  ngxDatatableMessage = {
    selectedMessage: 'selected',
    totalMessage: 'total',
    emptyMessage: `Aucun résultat`
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    setTimeout(() => {
      if (this.table && this.table.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {

        if (this.items)
          this.items = [...this.items]
      }
    }, 200);
  }


  constructor(
    private apiService: ApiService,
    public changeDetectorRef: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loader: AppLoaderService,
    private formModalService: FormModalService
  ) {
    super();
    this.generateBreakPoints();
    this.setGetDataCallBack(this.getUserCampaignTracking);
   }


  generateBreakPoints() {
    // Setting up breakpoint mechanism
    const breakpoints = Object.keys(this.LAYOUT).map(k => this.LAYOUT[k]);
    breakpoints.forEach((maxWidth, index) => {
      const minWidth = (index > 0) ? breakpoints[index - 1] : 0;
      this.breakpointObserver
        .observe([`(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`])
        .subscribe((state: BreakpointState) => {
          if (!state.matches) { return; }
          this.layout = maxWidth;

        });
    });
  }

  // setPage(pageInfo) {
  //   this.pageNumber = pageInfo.offset;
  //   this.getUserCampaignTracking(this.pageNumber);
  // }

  getUserCampaignTracking(pageNumber: number, orderBy: string, orderDir: string) {
    this.apiService.getEveryoneCampaignTracking(this.userId, pageNumber, this.per_page, orderBy, orderDir)
      .subscribe(
        reponse => {
          if (reponse && reponse['error'] == false) {
            this.totalElements = reponse['tracking']['totalCount'];
            this.items = reponse['tracking']['paginatedResults'];
          }
        },
        error => {
          //this.loading = false;
        },
        () => {
          //this.loading = false;
        }
      );
  }

  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition
    });
  }

  removeItemFromArrayByComparaison(items, item){

    let itemsList = [];
    for (let object of items){
      let count = 0;
      for (let [key, value] of Object.entries(item)) {
        if(object[key] == value)
          count++;
      }
      if( !(Object.entries(item).length && (Object.entries(item).length == count) && count > 0) ){
        itemsList.push(object);
      }
    }
    return itemsList;
  }

  removeItem(row) {
    let i = this.items.indexOf(row);
    this.items.splice(i, 1);
    if (this.items)
      this.items = [...this.items]
  }
  
  updateItem(id, item) {
    this.items = this.items.map(i => {
      if(i._id === id) {
        return Object.assign({}, i, item);
      }
      return i;
    })
  }


  onEdit(row) {
    console.log("row: ", row);

   if(row.user)
    row.lastName = row.user.lastName;
   this.formModalService.editCampaignTracking(row, false, this, 'items');
  }

  deleteItem(row) {

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      maxWidth: '450px',
      data: {
        message: this.customText.admin_campaign_delete_tracking.text,
        btnOk: this.customText.admin_campaign_delete_tracking.btnOk,
        btnCancel: this.customText.admin_campaign_delete_tracking.btnCancel
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.choice && result.choice == true) {
        this.apiService.adminDeleteCampaignTracking([row._id])
          .subscribe(
            reponse => {
              if (reponse && reponse['error'] == false && reponse['message']['deletedCount'] >= 1) {
                this.removeItem(row);
                this.totalElements--;
                this.snackBar.open(this.customText.snackBar_admin_campaign_deleted.text, "", {
                  duration: this.customText.snackBar_admin_campaign_deleted.duration,
                  verticalPosition: this.customText.snackBar_admin_campaign_deleted.verticalPosition,
                  horizontalPosition: this.customText.snackBar_admin_campaign_deleted.horizontalPosition
                });

              } else {
                this.displayErrorSnackbar("Error deleting campaign reservation");
              }
            },
            error => {
              this.displayErrorSnackbar(error);
            },
            () => {

            }
          )
      }
    });
  }


}

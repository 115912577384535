import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: any,  legends?: Array<any>): any {
    let res = value.split("-");
    return res[0] + legends[0] + " " + res[1] + legends[1] + " " + res[2] + legends[2];
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Campain } from '../../../../helpers/interfaces';
import { dayDiff } from '../../../../helpers/func';

@Component({
  selector: 'app-total',
  templateUrl: './total.component.html',
  styleUrls: ['./total.component.scss']
})
export class TotalComponent implements OnInit {
  @Input() dateBegin: Date;
  @Input() dateEnd: Date;
  @Input() selectedCampaign;
  customText: any = window['customText'][window['language']];
  campaign_type: { [name: string]: any }  = this.customText.campaign_type;
  tva: number = window['tva'];
  constructor() { }

  ngOnInit() {
  }
  getDaysCount(){
    let result: number = Number(dayDiff(this.dateBegin, this.dateEnd));
    return result;
  }

  getTotal(){
    return Number(((this.campaign_type[this.selectedCampaign].price * this.getDaysCount())/100 *  this.tva) + this.campaign_type[this.selectedCampaign].price * this.getDaysCount()).toFixed(2);
  }
  getTva(){
    return ((this.campaign_type[this.selectedCampaign].price * this.getDaysCount())/100 *  this.tva).toFixed(2);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-multi-stats-board-item',
  templateUrl: './multi-stats-board-item.component.html',
  styleUrls: ['./multi-stats-board-item.component.scss']
})
export class MultiStatsBoardItemComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() icon: string;
  @Input() icon_color: string;
  @Input() value: number;
  @Input() type: string;
  @Input() color: string;

  watcher: Subscription;
  activeMediaQuery = '';

  constructor(private mediaObserver: MediaObserver) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';

      if (change.mqAlias == 'xs') {
      }
    });
  }

  ngOnInit() {
  }

  getFileExtension(file: string) {
    var regexp = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    var extension = file.match(regexp);
    return extension && extension[1];
  }

  isExtSvg(name: string) {
    let ext = this.getFileExtension(name);
    return (ext && ext == "svg") || false;
  }

}

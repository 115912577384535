import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatProgressBar, MatButton, MatInput } from '@angular/material';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AlertModalComponent } from '../../shared/components/alert-modal/alert-modal.component';
import { StateService } from '@uirouter/core';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';
import { DisableMatViewElements } from '../../helpers/DisableMatViewElements';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent extends DisableMatViewElements implements OnInit {

  @ViewChild(MatProgressBar, { static: false }) progressBar: MatProgressBar;
  @ViewChild(MatButton, { static: false }) submitButton: MatButton;
  //@ViewChildren(MatButton) components:QueryList<MatButton>;

  userEmail: string;
  customText: any = window['customText'][window['language']];
  contactEmail: String = window['contactEmail'];
  contactPhone: String = window['contactPhone'];
  appUrl: String = window['appUrl'];

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public stateService: StateService,
    private authenticationService: AuthenticationService
  ) { 
    super();
  }

  ngOnInit() {

  }

  progressBarMode(mode) {
    if (this.progressBar && this.progressBar.mode)
      this.progressBar.mode = mode;
  }

  async submitEmail() {
    this.disableAll();
    //this.submitButton.disabled = true;
    this.progressBarMode('indeterminate');

    try {
      let resultat = await this.authenticationService.resetPassword(this.userEmail)
        .pipe(first())
        .subscribe(

          reponse => {
            this.progressBarMode('determinate');
            this.enableAll();
            //this.submitButton.disabled = false;
            const dialogRef = this.dialog.open(AlertModalComponent, {
              maxWidth: '350px',
              data: {
                message: this.customText.login_forgot_password_retrieve_success.text, btnOk: this.customText.login_forgot_password_retrieve_success.btnOk,
                icon: { maticon: "warning", style: { 'color': 'indianred', 'height': '34px', 'width': '34px', 'font-size': '34px' } }
              }
            });

            dialogRef.afterClosed().subscribe(result => {
              this.snackBar.open(this.customText.snackBar_forgot_password.text, "", {
                duration: this.customText.snackBar_forgot_password.duration,
                verticalPosition: this.customText.snackBar_forgot_password.verticalPosition,
                horizontalPosition: this.customText.snackBar_forgot_password.horizontalPosition
              });
              this.stateService.go('login');
            });
          },

          error => {
            this.progressBarMode('determinate');
            this.enableAll();
            //this.submitButton.disabled = false;
            this.snackBar.open(this.customText.snackBar_login_error.text, "", {
              duration: this.customText.snackBar_login_error.duration,
              verticalPosition: this.customText.snackBar_login_error.verticalPosition,
              horizontalPosition: this.customText.snackBar_login_error.horizontalPosition
            });
            //this.spinner.hide();
          });

    } catch (error) {

    }
  }

}

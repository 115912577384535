import { Component, Output, Input, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tab-selector',
  templateUrl: './tab-selector.component.html',
  styleUrls: ['./tab-selector.component.scss']
})
export class TabSelectorComponent implements OnInit {
  @Output() selectionChange = new EventEmitter<any>();
  @Input() choices: Array<any> = [];
  @Input() selected: any;

  constructor() { }

  ngOnInit() {
  }

  onClick(choice: any): void {
    this.selected = choice;
    this.selectionChange.emit(choice);
  }
}

import { Component, OnInit, Input, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss']
})
export class AudienceComponent implements OnInit {
  @Input() dataEntries: any;

  doughnutData = [];

  doughnutChartOptions: any = Object.assign({
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'right',
      labels: {
        usePointStyle: true
      }
    },
  });

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if (changes['dataEntries'] && this.dataEntries) {
      this.refresh();
    }
  }

  refresh(){
    if(this.dataEntries)
      this.setDataDonut(this.dataEntries);
  }

  setDataDonut(charts){
    if(!charts)
      return;
    
    this.doughnutData = [];
    charts.forEach( (element, index) => {
      if(element.type == 'pie'){
        this.doughnutData.push({
          data: [],
          labels: [],
          colors: [{
            backgroundColor: []
          }]
        });
        for (let el of element.data){
          this.doughnutData[this.doughnutData.length-1].data.push(el.percent);
          this.doughnutData[this.doughnutData.length-1].labels.push(el.label + ' - ' + el.percent + "%");
          this.doughnutData[this.doughnutData.length-1].colors[0].backgroundColor.push(el.color);
        }
      } else {
        this.doughnutData.push({});
      }
    });

  }
}


import { Component, OnInit, Output, ChangeDetectorRef, HostListener, ViewChild, EventEmitter, Input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ApiService } from "../../../../services/api.service";
import { defaultAnimations } from "../../../../shared/animations/default-animations";
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-campaign-history',
  templateUrl: './campaign-history.component.html',
  styleUrls: ['./campaign-history.component.scss'],
  animations: defaultAnimations
})
export class CampaignHistoryComponent implements OnInit {
  @Input() icon: string = "search_gray.svg";
  @Output() campaignSelected = new EventEmitter<any>();

  // @ViewChild('tableWrapper', { static: false }) tableWrapper;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  public readonly LAYOUT = {
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560,
  };
  public layout: number = this.LAYOUT.MD;
  customText: any = window['customText'][window['language']];
  public selected: any = [];
  public items: any[];
  public displayedColumns: string[] = ['campaignName', 'campainDateBegin', 'campaignDateEnd', 'campaignStatus'];
  private currentComponentWidth: number;
  public pageNumber: number = 0;
  public per_page: number = 3;
  public totalElements: number = 0;
  private firstInit: boolean = true;
  public loading: boolean = false;
  private defaultItems: any;
  private shadowItems: any;

  ngxDatatableMessage = {
    selectedMessage: 'choisi',
    totalMessage: 'total',
    emptyMessage: `Aucun résultat`
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {

  //   setTimeout(() => {
  //     if (this.table && this.table.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {

  //       if (this.items)
  //         this.items = [...this.items]
  //     }
  //   }, 200);
  // }

  constructor(
    private apiService: ApiService,
    public changeDetectorRef: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver
  ) {
    this.generateBreakPoints();
  }


  ngOnInit() {

    this.apiService.getCampaignTrackingHistory(null, null)
      .subscribe(
        reponse => {
          // console.log("reponse getCampaignTrackingHistory getCampaignTrackingHistory: ", reponse);
          if (reponse && reponse['error'] == false) {
            this.shadowItems = reponse['campaigns']['paginatedResults'];
          }
        },
        error => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    this.setPage({ offset: 0 });
  }

  campaignStatus(startCampaignDate: Date, endCampaignDate: Date) {
    const today = new Date();
    if (today > new Date(endCampaignDate))
      return {
        label: 'Terminé',
        color: '#7D8EA9'
      };
    if (today < new Date(startCampaignDate))
      return {
        label: 'Actif',
        color: '#38C871'
      };
    if (today >= new Date(startCampaignDate) && today <= new Date(endCampaignDate))
      return {
        label: 'En cours',
        color: '#FEAA02'
      };
  }

  onSearchChange(_search: string) {
    if (!_search) {
      this.items = this.defaultItems;
      this.items = this.items.map(el => {
        el.status = this.campaignStatus(el.campainDateBegin, el.campaignDateEnd);
        return el;
      })
      console.log("this.items: ", this.items);
    } else {
      this.items = this.shadowItems.filter((x) => x.campaignName.trim().toLowerCase().includes(_search.trim().toLowerCase()))
      this.items = this.items.map(el => {
        el.status = this.campaignStatus(el.campainDateBegin, el.campaignDateEnd);
        return el;
      })
      console.log("this.items: ", this.items);
    }
  }

  generateBreakPoints() {
    // Setting up breakpoint mechanism
    const breakpoints = Object.keys(this.LAYOUT).map(k => this.LAYOUT[k]);
    breakpoints.forEach((maxWidth, index) => {
      const minWidth = (index > 0) ? breakpoints[index - 1] : 0;
      this.breakpointObserver
        .observe([`(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`])
        .subscribe((state: BreakpointState) => {
          if (!state.matches) { return; }
          this.layout = maxWidth;
        });
    });
  }

  setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.getCampaignTrackingHistory(this.pageNumber);
  }

  getCampaignTrackingHistory(pageNumber) {
    this.loading = true;
    this.apiService.getCampaignTrackingHistory(pageNumber, this.per_page)
      .subscribe(
        reponse => {
          console.log("reponse: ", reponse);
          if (reponse && reponse['error'] == false) {
            this.totalElements = reponse['campaigns']['totalCount'];
            this.items = reponse['campaigns']['paginatedResults'];
            this.items = this.items.map(el => {
              el.status = this.campaignStatus(el.campainDateBegin, el.campaignDateEnd);
              return el;
            })
            console.log("this.items: ", this.items);
            this.defaultItems = reponse['campaigns']['paginatedResults'];
            this.defaultItems = this.defaultItems = this.defaultItems.map(el => {
              el.status = this.campaignStatus(el.campainDateBegin, el.campaignDateEnd);
              return el;
            })
            console.log("this.defaultItems: ", this.defaultItems);
            if (this.items && this.items[0] && this.firstInit) {
              this.onSelect({ selected: [this.items[0]] });
              this.firstInit = false;
            }
          }
        },
        error => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  onSelect({ selected }) {
    this.selected = [selected[0]];
    this.campaignSelected.emit(selected[0]);
  }

  getId(row): string {
    return row._id;
  }

  getStatusColor(status: string): string {
    switch (status) {
      case 'active':
        return 'green';
      case 'inactive':
        return 'red';
      case 'pending':
        return 'yellow';
      default:
        return 'transparent';  // Default background color
    }
  }
}

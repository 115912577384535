import { Component, OnInit } from '@angular/core';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import * as moment from 'moment';
import { ApiService } from "../../../services/api.service";
import { AppLoaderService } from '../../../services/app-loader/app-loader.service';
import { ShortNumberPipe } from 'src/app/pipes/short-number.pipe';

@Component({
  selector: 'app-back-office-dashboard-container',
  templateUrl: './back-office-dashboard-container.component.html',
  styleUrls: ['./back-office-dashboard-container.component.scss']
})
export class BackOfficeDashboardContainerComponent implements OnInit {
  miniStatList: Array<any> = [];
  customText: any = window['customText'][window['language']];
  periodSelectChoice: any[] = this.customText.dashboard_period_select;

  periodSelect: string;
  gbDataStats: any = null;

  audience: any = null;

  choices: Array<any> = [
    { label: "TOUS", value: "all" },
    { label: "IOS", value: "iphone", icon: ['fab', 'apple'] },
    { label: "ANDROID", value: "android", icon: ['fab', 'android'] },
    { label: "TABLETTE", value: "ipad", icon: ['fas', 'tablet-alt'] }
  ]
  selectedDevice: any = this.choices[0];

  constructor(
    private apiService: ApiService,
    private loader: AppLoaderService
  ) { }

  fillExtraStatistics(inputDatas: any = null) {

    this.audience = [
      {
        height: "70px",
        type: "pie",
        title: "Sexe",
        data: [
          { label: "Masculin", color: "#7BB7FF", percent: (inputDatas && inputDatas.male) ? inputDatas.male : 0 },
          { label: "Féminin", color: "#F287C5", percent: (inputDatas && inputDatas.female) ? inputDatas.female : 0 }
        ]
      },
      {
        type: "range",
        title: "Âge",
        color: "accent",
        data: [
          { label: "18-24", percent: (inputDatas && inputDatas.aud_18_24) ? inputDatas.aud_18_24 : 0 },
          { label: "25-34", percent: (inputDatas && inputDatas.aud_25_34) ? inputDatas.aud_25_34 : 0 },
          { label: "35-44", percent: (inputDatas && inputDatas.aud_35_44) ? inputDatas.aud_35_44 : 0 },
          { label: "45-54", percent: (inputDatas && inputDatas.aud_45_54) ? inputDatas.aud_45_54 : 0 },
          { label: "55+", percent: (inputDatas && inputDatas.aud_55_plus) ? inputDatas.aud_55_plus : 0 },

        ]
      }
    ]

  }

  ngOnInit() {
    this.periodSelect = "period-current-30";
    this.getAndroidAndIosNotificationStats()

    this.miniStatList.push(
      {
        name: "page_views",
        title: "Pages vues",
        color: '#6C6F75',
        icon: "catalogue.svg",
        count: 0,
        type: "number",
        details: ""
      }
    );

    this.miniStatList.push(
      {
        name: "launch",
        title: "Lancements",
        color: '#6C6F75',
        icon: "lancement_ecran.svg",
        count: 0,
        type: "number",
        details: ""
      }
    );
    this.miniStatList.push(
      {
        name: "notifications",
        title: "Notifications activés",
        color: '#6C6F75',
        icon: "notification.svg",
        count: 0,
        type: "number",
        details: ""
      }
    );

    // this.miniStatList.push(
    //   {
    //     name: "uniquelaunch",
    //     title: "Utilisateurs uniques",
    //     color: '#0AC1C7',
    //     icon: "profil.svg",
    //     count: 0,
    //     type: "number",
    //   }
    // );

    // this.miniStatList.push(
    //   {
    //     name: "download",
    //     title: "Nouveaux utilisateurs",
    //     color: '#0AC1C7',
    //     icon: "profil_groupe.svg",
    //     count: 0,
    //     type: "number",
    //   }
    // );
    /*
        this.miniStatList.push(
          {
            name: "newsletter",
            title: "Inscrits à la newsletter",
            color: '#0AC1C7',
            icon: "mail.svg",
            count: 0,
            type: "number",
          }
        );*/
    this.onChangePeriod();
  }

  changeMiniStatsValue(name, value) {
    this.miniStatList.forEach(element => {
      if (element.name == name)
        element.count = value;
    });
  }

  calculateCount(gbArray) {
    let total = 0;
    gbArray.forEach(element => {
      total += element.counter;
    });
    return total;
  }

  onChangePeriod() {
    let dates = this.calculatePeriodeDate(this.periodSelect);
    if (dates && dates.dateBegin && dates.dateEnd) {
      this.getGbAllStats(dates.dateBegin, dates.dateEnd, this.selectedDevice.value);
    }
  }

  getAndroidAndIosNotificationStats() {
    let defaultString: string = "";
    try {
      this.apiService.getExtraStatistics().subscribe((response: any) => {
        if (response && response.statistics && response.statistics.android_notifications && response.statistics.ios_notifications) {
          if (this.miniStatList.find(element => element.name == 'notifications')) {
            this.miniStatList.find(element => element.name == 'notifications').details = `Dont : ${new ShortNumberPipe().transform(response.statistics.ios_notifications)} iOS - ${new ShortNumberPipe().transform(response.statistics.android_notifications)} Android`;
          }
        }
      })
    } catch (error) {
      console.log("error: ", error);
      return defaultString;
    }
  }

  displayNumberOfNotifications() {
    this.apiService.getExtraStatistics().subscribe((response: any) => {
      if (this.selectedDevice.label === 'TOUS' || this.selectedDevice.label === 'TABLETTE') {
        this.changeMiniStatsValue("notifications", response.statistics.android_notifications + response.statistics.ios_notifications);
      }
      if (this.selectedDevice.label === 'IOS') {
        this.changeMiniStatsValue("notifications", response.statistics.ios_notifications);
      }
      if (this.selectedDevice.label === 'ANDROID') {
        this.changeMiniStatsValue("notifications", response.statistics.android_notifications);
      }
    });
  }

  getGbAllStats(dateBegin: string, dateEnd: string, plateform = "all") {
    this.loader.open("Veuillez patienter");
    this.apiService.getAllGbStats(dateBegin, dateEnd, plateform)
      .subscribe(
        reponse => {
          if (reponse && reponse['error'] == false) {
            this.gbDataStats = reponse['message'];
            this.gbDataStats["topdays"].splice(6, 0, this.gbDataStats["topdays"].splice(0, 1)[0]);

            this.changeMiniStatsValue("page_views", this.calculateCount(this.gbDataStats.pageviews));
            this.changeMiniStatsValue("uniquelaunch", this.calculateCount(this.gbDataStats.uniquelaunch));
            this.changeMiniStatsValue("launch", this.calculateCount(this.gbDataStats.launch));
            this.changeMiniStatsValue("download", this.calculateCount(this.gbDataStats.download));
            this.displayNumberOfNotifications();
            if (this.gbDataStats.extraStats && this.gbDataStats.extraStats.newsletter)
              this.changeMiniStatsValue("newsletter", this.gbDataStats.extraStats.newsletter);

            this.fillExtraStatistics(this.gbDataStats.extraStats);
          }
        },
        error => {
          this.loader.close();
        },
        () => {
          this.loader.close();
        }
      );
  }

  calculatePeriodeDate(selectedPeriod): any {

    let dateBegin: string = "";
    let dateEnd: string = "";
    let currentDate = moment(new Date());

    const words = selectedPeriod.split('-');
    if (!(words.length == 3 && words[0] == "period" && (words[1] == "month" || words[1] == "current")))
      return null;

    switch (words[1]) {

      case "month":
        if (words[2] == 0) {
          dateBegin = currentDate.clone().startOf('month').format('YYYY-MM-DD');
          dateEnd = currentDate.clone().format('YYYY-MM-DD');
        } else {
          let month = currentDate.clone().subtract(words[2], 'month');
          dateBegin = month.clone().startOf('month').format('YYYY-MM-DD');
          dateEnd = currentDate.clone().subtract(1, "month").endOf('month').format('YYYY-MM-DD');
        }

        break;

      case "current":
        let b = currentDate.clone().subtract(words[2], 'day');
        dateBegin = b.format('YYYY-MM-DD');
        dateEnd = currentDate.format('YYYY-MM-DD');
        break;
    }

    return { dateBegin, dateEnd }
  }

  onChangeDevice(device: any): void {
    this.selectedDevice = device;
    this.onChangePeriod();
  }

}

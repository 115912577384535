import { Injectable, AfterViewInit, OnInit } from '@angular/core'
import { TabPage } from './interfaces';

@Injectable()
export class NgxDatatableHelper implements OnInit {

    private debouncer: any;
    public getData: any;

    public page: TabPage = {
        offset: 0,
        orderBy: 'createDate',
        orderDir: 'desc'
    };

    constructor() {
    }

    ngOnInit() {
        this.refresh();
    }

    ngAfterViewInit() {
    }

    sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }): void {
        let orderDir = sortInfo.sorts[0].dir;
        let orderBy = sortInfo.sorts[0].prop;
    
        this.page.offset = 0;
        this.setPage({ offset: this.page.offset, orderBy, orderDir });
    }

    refresh(): void{
        clearTimeout(this.debouncer);
        this.debouncer = setTimeout(() => {
            this.getData(this.page.offset, this.page.orderBy, this.page.orderDir);
        }, 100);  
    }

    setPage(pageInfo): void {
        clearTimeout(this.debouncer);
        this.debouncer = setTimeout(() => {
          this.page.offset = pageInfo.offset != undefined ? pageInfo.offset : this.page.offset;
          this.page.orderBy = pageInfo.orderBy != undefined ? pageInfo.orderBy : this.page.orderBy;
          this.page.orderDir = pageInfo.orderDir != undefined ? pageInfo.orderDir : this.page.orderDir;
          this.getData(this.page.offset, this.page.orderBy, this.page.orderDir);
        }, 100);  
    }

    setGetDataCallBack(func: any){
        this.getData = func;
    }
    
}
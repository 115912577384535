import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { StateService } from '@uirouter/angular';
import { AuthenticationService } from '../services/authentication.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiUrl: string = "";
  constructor(private httpClient: HttpClient,
    private Auth: AuthenticationService,
    private stateService: StateService) {
    // @host
    this.apiUrl = window['apiUrl'];
    // this.apiUrl = 'http://localhost:4000';

  }

  errorHandler(error) {
    if (error.status == 401) {
      this.Auth.logout();
      this.stateService.transitionTo('login');
    }
  }

  private handleError = (error: HttpErrorResponse) => {

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      if (error.status == 401) {
        this.Auth.logout();
        this.stateService.transitionTo('login');
      }
      console.error(`Backend returned code ${error.status}`);
    }
    // return an observable with a user-facing error message
    if (error.error.message)
      return throwError(error.error.message);
    else
      return throwError('Something bad happened; please try again later.');

  };


  //dashboard
  getExtraStatistics() {
    return this.httpClient.get(this.apiUrl + `/api/dashboard/extraStatistics`)
      .pipe(catchError(this.handleError));
  }

  updateExtraStatistics(form: any) {
    return this.httpClient.post(this.apiUrl + `/api/admin/dashboard/extraStatistics`, form)
      .pipe(catchError(this.handleError));
  }

  getAllGbStats(starting_at, ending_at, platform = "all") {
    let params = {}
    params = {
      starting_at: starting_at,
      ending_at: ending_at,
      platform: platform
    }

    return this.httpClient.get(this.apiUrl + `/api/dashboard/getAllStats`, { params: { ...params } })
      .pipe(catchError(this.handleError));
  }

  //Misc
  upload(fileForm: FormData) {
    return this.httpClient.post(this.apiUrl + `/api/files/upload`, fileForm)
      .pipe(catchError(this.handleError));
  }

  //Admin
  getOpenedCatalogPositionListSummary() {

    return this.httpClient.get(this.apiUrl + `/api/campaignTracking/catalog`)
      .pipe(catchError(this.handleError));
  }

  getResponsables() {
    return this.httpClient.get(this.apiUrl + '/api/users/getAllResponsable')
      .pipe(catchError(this.handleError))
  }

  //Users
  getCompanies(offset: Number = null, limit: Number = null) {
    let params = {}

    params['offset'] = JSON.stringify(offset);
    params['limit'] = JSON.stringify(limit);

    return this.httpClient.get(this.apiUrl + `/api/campaignTracking/companies`, { params: { ...params } })
      .pipe(catchError(this.handleError));
  }

  getOpenedCatalogPositions(catalogId: number, page: Number = null, per_page: Number = null) {
    let params = {}

    if (per_page && page) {
      params['page'] = JSON.stringify(page);
      params['per_page'] = JSON.stringify(per_page);
    }

    return this.httpClient.get(this.apiUrl + `/api/campaignTracking/catalog/${catalogId}`, { params: { ...params } })
      .pipe(catchError(this.handleError));
  }

  validateOpenCatalogId(catalogId: number) {
    let params = {}
    params = {
      catalogId: catalogId,
    }
    return this.httpClient.get(this.apiUrl + `/api/campaignTracking/validate-open-catalog`, { params: { ...params } })
      .pipe(catchError(this.handleError));
  }

  getDatesTaken() {
    return this.httpClient.get(this.apiUrl + `/api/campaign/getDatesTaken`)
      .pipe(catchError(this.handleError));
  }


  reservateCampaign(campaignType: String, county: String, dateBegin: Date, dateEnd: Date, message: string) {

    var reservatingData = {
      campaignType,
      county,
      dateBegin,
      dateEnd,
      message
    }
    console.log("reservatingData: ", reservatingData);

    return this.httpClient.post(this.apiUrl + '/api/campaign/reservate', reservatingData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getUploadUserHistory(pageNumber: Number, perPage: Number) {

    let params = {}
    params = {
      page: JSON.stringify(pageNumber),
      per_page: JSON.stringify(perPage)
    }

    return this.httpClient.get(this.apiUrl + `/api/users/getUploadUserHistory`, { params: { ...params } })
      .pipe(catchError(this.handleError));
  }

  getUserLatestCampaignTracking() {
    return this.httpClient.get(this.apiUrl + `/api/campaignTracking`)
      .pipe(catchError(this.handleError));
  }

  getCampaignTrackingHistory(pageNumber: Number, perPage: Number) {

    let params = {}
    params = {
      page: JSON.stringify(pageNumber),
      per_page: JSON.stringify(perPage)
    }

    return this.httpClient.get(this.apiUrl + `/api/campaignTrackingHistory`, { params: { ...params } })
      .pipe(catchError(this.handleError));
  }


  //Admin only
  adminDeleteCatalogs(catalogIds: string[]) {
    var messageData = {
      catalogs: catalogIds
    }
    return this.httpClient.post(this.apiUrl + `/api/admin/deleteCatalog`, messageData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  adminDeleteCampaignTracking(campaignTrackingIds: string[]) {
    var messageData = {
      campaignsTracking: campaignTrackingIds
    }
    return this.httpClient.post(this.apiUrl + `/api/admin/deleteCampaignTracking`, messageData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  adminEditCampaignTracking(campaignTrackingId: String, fileForm: FormData) {
    console.log("fileForm: ", fileForm);
    return this.httpClient.put(this.apiUrl + `/api/admin/campaignTracking/${campaignTrackingId}`, fileForm)
      .pipe(catchError(this.handleError));
  }

  deletePdfFilesFromCampaignTracking(subDocumentId: string, subDocumentsIdToDelete: string[]) {
    return this.httpClient.post(this.apiUrl + `/api/admin/deletePdfFilesFromCampaignTracking/${subDocumentId}`, { subDocumentsId: subDocumentsIdToDelete })
      .pipe(catchError(this.handleError));
  }

  adminAddCampaignTracking(fileForm: FormData) {
    return this.httpClient.post(this.apiUrl + `/api/admin/campaignTracking`, fileForm)
      .pipe(catchError(this.handleError));
  }

  adminEditCampaign(campaignId: string, campaignType: String, county: String, dateBegin: Date, dateEnd: Date, validated: boolean) {

    var reservatingData = {
      campaignType,
      county,
      dateBegin,
      dateEnd,
      validated
    }

    return this.httpClient.put(this.apiUrl + `/api/admin/campaign/${campaignId}`, reservatingData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  adminDeleteCampaignReservation(reservationsIds: string[]) {
    var messageData = {
      campaigns: reservationsIds
    }
    return this.httpClient.post(this.apiUrl + `/api/admin/deleteCampaignReservations`, messageData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  adminDeleteFiles(files: string[]) {
    var messageData = {
      files: files
    }
    return this.httpClient.post(this.apiUrl + `/api/admin/deleteUploadedFiles`, messageData, httpOptions)
      .pipe(catchError(this.handleError));
  }

  getEveryoneUploadHistory(userId: string, pageNumber: Number = null, perPage: Number = null) {

    let params = {}
    params = {
      page: JSON.stringify(pageNumber),
      per_page: JSON.stringify(perPage)
    }

    if (userId && userId.length)
      params['userId'] = userId;

    return this.httpClient.get(this.apiUrl + `/api/admin/getEveryoneUploadHistory`, { params: { ...params } })
      .pipe(catchError(this.handleError));
  }


  getEveryoneCampaignTracking(userId: string, pageNumber: Number = null, perPage: Number = null, orderBy: string = null, orderDir: string = null) {

    let params = {}
    params = {
      page: JSON.stringify(pageNumber),
      per_page: JSON.stringify(perPage)
    }

    if (orderBy && orderDir) {
      params['orderBy'] = orderBy;
      params['orderDir'] = orderDir;
    }

    if (userId && userId.length)
      params['userId'] = userId;

    return this.httpClient.get(this.apiUrl + `/api/admin/getEveryoneCampaignTracking`, { params: { ...params } })
      .pipe(catchError(this.handleError));
  }


  getEveryoneReservations(userId: string, pageNumber: Number = null, perPage: Number = null) {

    let params = {}
    params = {
      page: JSON.stringify(pageNumber),
      per_page: JSON.stringify(perPage)
    }

    if (userId && userId.length)
      params['userId'] = userId;

    return this.httpClient.get(this.apiUrl + `/api/admin/getEveryoneReservations`, { params: { ...params } })
      .pipe(catchError(this.handleError));
  }

  adminGetUsers(pageNumber: Number = null, perPage: Number = null) {
    let params = {}
    if (pageNumber && perPage) {
      params = {
        page: JSON.stringify(pageNumber),
        per_page: JSON.stringify(perPage)
      }
    }
    return this.httpClient.get(this.apiUrl + `/api/admin/users`, { params: { ...params } })
      .pipe(catchError(this.handleError));
  }

  adminDeleteUser(userId: String) {
    return this.httpClient.delete(this.apiUrl + `/api/admin/users/${userId}`)
      .pipe(catchError(this.handleError));
  }

  adminEditUser(userId: String, form: any) {

    return this.httpClient.put(this.apiUrl + `/api/admin/users/${userId}`, form)
      .pipe(catchError(this.handleError));
  }

  adminEditRoleUserToResponsable(userId: String, role: any) {
    return this.httpClient.put(this.apiUrl + `/api/admin/users/responsable/${userId}`, role)
      .pipe(catchError(this.handleError));
  }
  adminAssignResponsableToUser(userId: String, responsableId: String) {
    return this.httpClient.put(this.apiUrl + `/api/admin/users/assignResponsable/${userId}`, { responsableId: responsableId })
      .pipe(catchError(this.handleError));
  }

  getJoomagStatistics(catalogId: any, dateStart: any, dateEnd: any) {
    return this.httpClient.get(this.apiUrl + `/api/getJoomagStatistics`, { params: { catalogId: catalogId, dateStart: dateStart, dateEnd: dateEnd }})
      .pipe(catchError(this.handleError));
  }

  updateJoomagStatistics(campaignTrackingId: String, stats: any) {
    // console.log("Joomag Statistics: ", stats);
    return this.httpClient.put(this.apiUrl + `/api/updateJoomagStatistics/${campaignTrackingId}`, stats)
      .pipe(catchError(this.handleError));
  }
}

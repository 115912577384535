import { Component, OnInit, Input } from '@angular/core';
import { Campain } from '../../../../helpers/interfaces';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-campaign-selected',
  templateUrl: './campaign-selected.component.html',
  styleUrls: ['./campaign-selected.component.scss']
})
export class CampaignSelectedComponent implements OnInit {
  @Input() campaign: any;
  campaignDocuments: any;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getUserLatestCampaignTracking().subscribe((response: any) => {
      this.campaign['documents'] = response.campaignTracking.campaignDocuments ? response.campaignTracking.campaignDocuments : [];
    });
  }
}

import { NgModule } from '@angular/core';
import { RootModule, UIRouterModule, Transition, TransitionService } from '@uirouter/angular';

import { ApiService } from "./services/api.service";

import { LoginComponent } from './auth/login/login.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { SignupComponent } from './auth/signup/signup.component';

import { BackOfficeLayoutComponent } from './backOffice/backOffice-layout/back-office-layout.component';
import { BackOfficeCampaignTrackingContainerComponent } from './backOffice/backOffice-campaign-tracking/backOffice-campaign-tracking-container/back-office-campaign-tracking-container.component';
import { BackOfficePublishContainerComponent } from './backOffice/backOffice-publish/back-office-publish-container/back-office-publish-container.component';
import { BackOfficeDashboardContainerComponent } from './backOffice/backOffice-dashboard/back-office-dashboard-container/back-office-dashboard-container.component';
import { BackOfficeAdvertisingManagementContainerComponent } from './backOffice/backOffice-advertising-management/back-office-advertising-management-container/back-office-advertising-management-container.component';


import { BackOfficeAdminLayoutComponent } from './backOfficeAdmin/backOffice-admin-layout/back-office-admin-layout.component';
import { BackOfficeAdminCampaignsComponent } from './backOfficeAdmin/back-office-admin-campaigns/back-office-admin-campaigns.component';
import { BackOfficeAdminUsersComponent } from './backOfficeAdmin/backOffice-admin-users/back-office-admin-users.component';
import { BackOfficeAdminUploadComponent } from './backOfficeAdmin/back-office-admin-upload/back-office-admin-upload.component';
import { AuthenticationService } from './services/authentication.service';
import { BackOfficeAdminCampaignsTrackingComponent } from './backOfficeAdmin/back-office-admin-campaigns-tracking/back-office-admin-campaigns-tracking.component';
import { BackOfficeAdminStatisticsComponent } from './backOfficeAdmin/back-office-admin-statistics/back-office-admin-statistics.component';
import { BackOfficeAdminCatalogComponent } from './backOfficeAdmin/back-office-admin-catalog/back-office-admin-catalog.component';

export async function redirectRoleToRightPage(uiRouter, injector, statesModule) {
  let authSvc = injector.get(AuthenticationService);

  uiRouter.urlService.rules.otherwise((matchValue, url, router) => {
    let userRole = authSvc.getUserRole();
    if (userRole == "user")
      router.stateService.go("dashboard");
    else if (userRole == "admin")
      router.stateService.go("admin_users");
  });
}

export async function getUserIdParams(transition) {
  return transition.params().userId
}

export async function retrieveDatesTaken(apiService, trans) {
  let resolveDates = await apiService.getDatesTaken().toPromise();
  return { dates: resolveDates.dates };
}

export async function retrieveUserLatestCampaignTracking(apiService, trans) {
  let resolveCampaignTracking = await apiService.getUserLatestCampaignTracking().toPromise();
  return { campaignTracking: resolveCampaignTracking.campaignTracking };
}

export async function requireAuthentication(transition) {
  let $state = transition.router.stateService;
  let authSvc = transition.injector().get(AuthenticationService);

  if (!(await authSvc.isAuthenticated())) {
    authSvc.logout();
    return $state.target('login');
  }
}

export async function requireAuthenticationAdmin(transition) {
  let $state = transition.router.stateService;
  let authSvc = transition.injector().get(AuthenticationService);

  if (!(await authSvc.isAuthenticatedAdmin())) {
    authSvc.logout();
    return $state.target('login');
  }
}

export async function requireAuthenticationUser(transition) {
  let $state = transition.router.stateService;
  let authSvc = transition.injector().get(AuthenticationService);

  if (!(await authSvc.isAuthenticatedUser())) {
    authSvc.logout();
    return $state.target('login');
  }
}


export async function otherwiseRole(transition) {
}

// function($injector, $location){
//   $injector.get('$state').go('404');
// });


function getDashboardTitle() {
  let defaultTitle = "Tableau de bord";
  try {
    if (!localStorage.getItem('ileco_current_user')) {
      return defaultTitle;
    }
    const parsedUser = JSON.parse(localStorage.getItem('ileco_current_user'));
    if (!parsedUser || !parsedUser.firstName) {
      return defaultTitle;
    }
    return `Bonjour ${parsedUser.firstName}`;
  } catch (error) {
    console.log("get user error: ", error);
    return defaultTitle;
  }
}

const customText: any = window['customText'][window['language']];

const rootModule: RootModule = {
  states: [

    {
      name: "BackOfficeUser",
      component: BackOfficeLayoutComponent
    },
    {
      parent: "BackOfficeUser",
      name: "tracking",
      url: "/user/tracking",
      data: {
        pageTitle: customText.navbar_menu_tracking
      },
      onEnter: requireAuthenticationUser,
      component: BackOfficeCampaignTrackingContainerComponent,
      resolve: [
        {
          token: "resolved",
          deps: [ApiService, Transition],
          resolveFn: retrieveUserLatestCampaignTracking
        }
      ]
    },
    {
      parent: "BackOfficeUser",
      name: "publish",
      url: "/user/publish",
      data: {
        pageTitle: customText.navbar_menu_publish
      },
      onEnter: requireAuthenticationUser,
      component: BackOfficePublishContainerComponent
    },
    {
      parent: "BackOfficeUser",
      name: "dashboard",
      url: "/user/dashboard",
      data: {
        pageTitle: getDashboardTitle()
      },
      onEnter: requireAuthenticationUser,
      component: BackOfficeDashboardContainerComponent
    },
    {
      parent: "BackOfficeUser",
      name: "advertising",
      url: "/user/advertising",
      data: {
        pageTitle: customText.navbar_menu_advertising
      },
      onEnter: requireAuthenticationUser,
      component: BackOfficeAdvertisingManagementContainerComponent,
      resolve: [
        {
          token: "resolved",
          deps: [ApiService, Transition],
          resolveFn: retrieveDatesTaken
        }
      ]
    },
    {
      name: "BackOfficeAdmin",
      component: BackOfficeAdminLayoutComponent
    },
    {
      parent: "BackOfficeAdmin",
      name: "admin_campaigns_tracking",
      url: "/admin/campaigns_tracking/:userId",
      data: {
        pageTitle: customText.navbar_admin_menu_campaigns_tracking
      },
      onEnter: requireAuthenticationAdmin,
      component: BackOfficeAdminCampaignsTrackingComponent,
      resolve: [
        {
          token: "userId",
          deps: [Transition],
          resolveFn: getUserIdParams
        }
      ]
    },
    {
      parent: "BackOfficeAdmin",
      name: "admin_campaigns",
      url: "/admin/campaigns/:userId",
      data: {
        pageTitle: customText.navbar_admin_menu_campaigns
      },
      onEnter: requireAuthenticationAdmin,
      component: BackOfficeAdminCampaignsComponent,
      resolve: [
        {
          token: "userId",
          deps: [Transition],
          resolveFn: getUserIdParams
        }
      ]
    },
    {
      parent: "BackOfficeAdmin",
      name: "admin_users",
      url: "/admin/users",
      data: {
        pageTitle: customText.navbar_admin_menu_users
      },
      onEnter: requireAuthenticationAdmin,
      component: BackOfficeAdminUsersComponent
    },
    {
      parent: "BackOfficeAdmin",
      name: "admin_upload",
      url: "/admin/upload/:userId",
      data: {
        pageTitle: customText.navbar_admin_menu_upload
      },
      onEnter: requireAuthenticationAdmin,
      component: BackOfficeAdminUploadComponent,
      resolve: [
        {
          token: "userId",
          deps: [Transition],
          resolveFn: getUserIdParams
        }
      ]
    },
    {
      parent: "BackOfficeAdmin",
      name: "admin_statistics",
      url: "/admin/statistics",
      data: {
        pageTitle: customText.navbar_admin_menu_statistics
      },
      onEnter: requireAuthenticationAdmin,
      component: BackOfficeAdminStatisticsComponent
    },
    {
      parent: "BackOfficeAdmin",
      name: "admin_catalog",
      url: "/admin/catalog",
      data: {
        pageTitle: customText.navbar_admin_menu_catalogs
      },
      onEnter: requireAuthenticationAdmin,
      component: BackOfficeAdminCatalogComponent
    },
    {
      url: "/signup",
      name: "signup",
      component: SignupComponent,
    },
    {
      url: "/forgot",
      name: "forgot",
      component: ForgotComponent,
    },
    {
      name: "login",
      url: "/login",
      component: LoginComponent,
    }
  ],
  useHash: true,
  config: redirectRoleToRightPage

}

@NgModule({
  imports: [UIRouterModule.forRoot(rootModule)],
  exports: [UIRouterModule]
})
export class AppRoutingModule { }
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { StateService, TransitionService, Transition } from '@uirouter/core'
import { AuthenticationService } from '../../services/authentication.service';
import { MatDialog } from '@angular/material';
import { ConfirmationModalComponent } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { User } from '../../helpers/interfaces';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-back-office-layout',
  templateUrl: './back-office-layout.component.html',
  styleUrls: ['./back-office-layout.component.scss']
})
export class BackOfficeLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  customText: any = window['customText'][window['language']];
  contactEmail: String = window['contactEmail'];
  contactPhone: String = window['contactPhone'];
  selectPageTitle: String = "";
  currentUser: User;

  constructor(
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher, 
    public stateService: StateService, 
    private authenticationService: AuthenticationService, 
    public dialog: MatDialog, 
    public transitionService: TransitionService
    ) { 
      this.mobileQuery = media.matchMedia('(max-width: 960px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {

    if(this.stateService && this.stateService.current && this.stateService.current.data && this.stateService.current.data.pageTitle)
      this.selectPageTitle = this.stateService.current.data.pageTitle
    this.transitionService.onSuccess({}, (transition: Transition) => {
      let substate = transition.to();
      if(substate && substate.data && substate.data.pageTitle)
        this.selectPageTitle = substate.data.pageTitle;
    });

    this.currentUser = this.authenticationService.getCurrentUser();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logout(){
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { message: this.customText.logout_confirmation.text, btnOk: this.customText.logout_confirmation.btnOk, btnCancel: this.customText.logout_confirmation.btnCancel }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.choice && result.choice == true) {
        this.authenticationService.logout();
        this.stateService.transitionTo('login');
      }
    });

  }

  onClickSideNav(){
    if(this.mobileQuery.matches){
      this.sidenav.close();
    }
  }


}

import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from "../services/api.service";

@Injectable({
  providedIn: 'root'
})

export class OpenCatalogIdValidator {

  debouncer: any;

  constructor( private apiService: ApiService){

  }

  checkCatalogId(control: FormControl): any {

    clearTimeout(this.debouncer);

    return new Promise(resolve => {

      this.debouncer = setTimeout(() => {

        this.apiService.validateOpenCatalogId(control.value).subscribe((res: any) => {
          if(res.error == false && res.message >= 1){
            resolve(null);
          } else {
            resolve({ 'invalid catalog Id': true });
          }
        }, (err) => {
          resolve({ 'invalid catalog Id': true });
        });

      }, 1000);      

    });
  }

}
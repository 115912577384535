import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import * as moment from 'moment';
import * as inputmask from "inputmask";
import { ApiService } from 'src/app/services/api.service';
import { AppLoaderService } from 'src/app/services/app-loader/app-loader.service';
import { FormModalService } from 'src/app/services/form-modal.service';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent implements OnInit {
  customText: any = window['customText'][window['language']];
  form: FormGroup;
  inputs: Array<any> = this.data.inputs;
  submitted: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private loader: AppLoaderService,
    public dialogRef: MatDialogRef<FormModalComponent>,
    private formModalService: FormModalService,
    public dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.initFormGroup();
    this.checkItems(this.inputs);
  }

  ngAfterViewInit() {
    inputmask({ "insertMode": true, "clearIncomplete": true }).mask(document.querySelectorAll(".inputMask"));
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  initFormGroup() {
    let result = this.buildFormGroup(this.inputs);
    this.form = this.formBuilder.group(result);
  }

  checkItems(objects) {
    for (let object of objects) {
      if (object.type == 'slider' && object.disableOtherItemsOnState != undefined) {
        this.changeElementsState(object, !(object.disableOtherItemsOnState == this.form.controls[object.name].value));
      }
    }
  }
  changeElementsState(itemSource, enable: boolean) {
    for (let itemName in this.form.controls) {
      if (itemSource.name != itemName) {
        if (enable)
          this.form.controls[itemName].enable();
        else
          this.form.controls[itemName].disable();
      }
    }
  }

  onClick(item) {
    console.log("item: ", item);
    this.apiService.deletePdfFilesFromCampaignTracking(item._id, [item._id]).subscribe(
      reponse => {
        if (reponse && reponse['error'] == false) {
          console.log("reponse: ", reponse);
          // if (context && targetName && !isNew) {
          //   this.updateItem(data._id, reponse['campaign'], context, targetName);
          // }
          //this.updateItem(data._id, reponse['user'], context, targetName);
          this.dialogRef.close();
          this.formModalService.editCampaignTracking(reponse['campaign'], true, this, 'items');
          this.snackBar.open(this.customText.snackBar_admin_campaign_tracking_pdf_deleted.text, "", {
            duration: this.customText.snackBar_admin_campaign_tracking_pdf_deleted.duration,
            verticalPosition: this.customText.snackBar_admin_campaign_tracking_pdf_deleted.verticalPosition,
            horizontalPosition: this.customText.snackBar_admin_campaign_tracking_pdf_deleted.horizontalPosition
          });

        } else {
          this.displayErrorSnackbar("Une erreur s'est produite");
        }
      },
      error => {
        this.loader.close();
        this.displayErrorSnackbar(error);
      },
      () => {
        this.loader.close();
      }
    )
  }

  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition
    });
  }

  buildFormGroup(objects) {
    let formBuild = {};

    for (let item of objects) {
      let isArray = Array.isArray(item);

      if (!isArray)
        formBuild[item.name] = [item.value, ...item.validators];
      else
        formBuild = { ...formBuild, ...this.buildFormGroup(item) }
    }

    return formBuild;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid)
      return;

    this.dialogRef.close(this.form.getRawValue())
  }

  returnItemFromName(name: String) {
    for (let input of this.inputs) {
      if (input.name == name)
        return input;
    }
    return null;
  }

  removeItem(items, item) {
    let i = items.indexOf(item);
    items.splice(i, 1);
    if (items)
      items = [...items]
  }

  onChange(event, item) {

    if (item.type == 'slider' && item) {

      if (item.alertOnChange && item.alertOnChange.onlyWhenChecked == event.checked && this.form.controls[item.alertOnChange.linkedTo].value == item.alertOnChange.displayIf) {
        this.dialog.open(AlertModalComponent, {
          maxWidth: '350px',
          data: {
            message: item.alertOnChange.message, btnOk: item.alertOnChange.btnOk,
            icon: { maticon: "warning", style: { 'color': 'indianred', 'height': '34px', 'width': '34px', 'font-size': '34px' } }
          }
        });
      }
      /*
      if(item.linkedTo != undefined){
        let input = this.returnItemFromName(item.linkedTo);
        if(event.checked){

        } else {
          let itemToRemove = { 
            campainDateBegin: this.form.controls[input.name].value.startDate.toISOString(),
            campaignDateEnd: this.form.controls[input.name].value.endDate.toISOString() 
          };
          this.removeItem(input.invalideDates, itemToRemove);
        }
      }
      */
      if (item.disableOtherItemsOnState != undefined) {
        this.changeElementsState(item, !(item.disableOtherItemsOnState == event.checked));
      }
    }

  }

  checkIfMomentInsideDateArray(dateArray, moment) {
    if (dateArray && dateArray) {

      for (let dates of dateArray) {
        let dateA = dates.campainDateBegin;
        let dateB = dates.campaignDateEnd;

        let check = moment.isBetween(dateA, dateB, null, '[]');
        if (check == true)
          return true;
      }
    }
    return false;
  }


  isInvalideDateWrapper(item: any) {
    return (m: moment.Moment) => {
      return this.isInvalidDate(m, item)
    };
  }

  isInvalidDate = (m: moment.Moment, item: any) => {

    if (item && item.invalideDates) {

      let isInvalid = this.checkIfMomentInsideDateArray(item.invalideDates, m);
      if (item.invalideDatesLinkedTo && item.invalideDatesLinkedTo.input && item.invalideDatesLinkedTo.input.length &&
        this.form.controls[item.invalideDatesLinkedTo.input].value != item.invalideDatesLinkedTo.activeWhen) {
        isInvalid = false;
      }

      return (!moment(new Date()).isSameOrBefore(m, "day") || isInvalid);
    } else
      return false;
  }

}

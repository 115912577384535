import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeExt'
})
export class removeExtPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/\.[^.$]+$/, '');;
  }
}

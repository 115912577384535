import { Component, OnInit, Input, SimpleChange, ViewChild, ElementRef } from '@angular/core';
import '../../../../helpers/prototype';

@Component({
  selector: 'app-multi-stats',
  templateUrl: './multi-stats.component.html',
  styleUrls: ['./multi-stats.component.scss']
})
export class MultiStatsComponent implements OnInit {
  @ViewChild('canvasChart', { static: false }) canvas: ElementRef;
  @Input() dataTabs: any = [];
  firstInit = false;
  sharedChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1.3,
    legend: {
      display: true,
      position: 'bottom'
    }
  };
  /*
  chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(148,159,177,0.15)',
      borderColor: 'rgba(148,159,177,1.0)',
      pointBackgroundColor: 'rgba(148,159,177,1.0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
    {
      backgroundColor: 'rgba(148,0,0,0.15)',
      borderColor: 'rgba(148,0,0,1.0)',
      pointBackgroundColor: 'rgba(148,0,0,1.0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  */
  chartType = 'line';
  chartData: any[] = [
    {
      data: [],
      label: '',
      borderWidth: 0
    }
];
  chartOptions: any = Object.assign({
    scaleShowVerticalLines: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0.05)',
          zeroLineColor: 'rgba(0,0,0,0.02)'
        },
        ticks: {
          maxTicksLimit: 5,
          fontSize: 10,
          fontColor: "#DBDBDC",
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0
        },
        type: 'time',
        time: {
          minUnit: 'day'
        },
        distribution: 'linear'
      }],
      yAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0.05)',
          zeroLineColor: 'rgba(0,0,0,0.02)'
        },
        position: 'left',
        ticks: {
          callback: function(value, index, values) {
            return String(value).commarize(); 
          },
          beginAtZero: true,
          suggestedMax: 9,
          maxTicksLimit: 5
        }
      }]
    }
  }, this.sharedChartOptions);

  constructor() { }

  ngOnInit() {
  }
  
  ngAfterViewInit(){
    this.refresh();
  }


  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if (changes['dataTabs'] && this.dataTabs) {
      this.refresh();
    }
  }

  refresh(){
    if(this.dataTabs)
      this.setDataTab(this.dataTabs);
  }

  setDataTab(charts){
    if(!charts)
      return;

    this.chartData = [];
    charts.forEach( (element, index) => {
      let data = this.repackData(element.data);
      let backGroundColor = element.color.replace(/[^,]+(?=\))/, '0.15');
      if(data){
        if(this.canvas){
          backGroundColor = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 200);
          backGroundColor.addColorStop(0, element.color.replace(/[^,]+(?=\))/, '0.8'));
          backGroundColor.addColorStop(1, element.color.replace(/[^,]+(?=\))/, '0'));
        }

        this.chartData.push({
          data: [],
          label: element.label,
          borderWidth: 1,
          backgroundColor: backGroundColor,
          borderColor: element.color,
          pointBackgroundColor: element.color,
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        });

        Object.entries(data).forEach(([key, value]) => {
          this.chartData[index].data.push({ t: key, y: value });
        });
        
      }
    });

  }

  repackData(data){
    let objects = {}
    data.forEach( (element, index) => {
      if( !(index == data.length - 1 && element.counter == 0) )
        objects[element.collected_at] = objects[element.collected_at] ? objects[element.collected_at] + element.counter : element.counter;
    });
    return objects;
  }


}

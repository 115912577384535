import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-campaign-dates',
  templateUrl: './campaign-dates.component.html',
  styleUrls: ['./campaign-dates.component.scss']
})
export class CampaignDatesComponent implements OnInit {
  @Input() dateBegin: Date;
  @Input() dateEnd: Date;


  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

const exclusionList = ["getLocalSettings", "get_items", "goodbarber"];

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    urlContainerWord(wordList, url){
        for(let word of wordList){
            if(url.indexOf(word) != -1){
                return true;
            }
        }
        return false;
    }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('ileco_current_user'));
        if(this.urlContainerWord(exclusionList, request.url)) 
            return next.handle(request);
        //console.log("intercept", request.url, currentUser)
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request);
    }
}
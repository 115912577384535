import { Injectable } from '@angular/core';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiService } from "./api.service";
import { FormModalComponent } from '../shared/components/form-modal/form-modal.component';
import { FormGroup, Validators } from '@angular/forms';
import { AppLoaderService } from '../services/app-loader/app-loader.service';
import { OpenCatalogIdValidator } from '../helpers/openCatalogIdValidator';

import { Campain } from '../helpers/interfaces';
import { dayDiff } from '../helpers/func';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FormModalService {
  customText: any = window['customText'][window['language']];
  campaign_type: { [name: string]: Campain } = this.customText.campaign_type;
  tva: number = window['tva'];
  counties: any = this.customText.counties;

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private loader: AppLoaderService,
    public openCatalogIdValidator: OpenCatalogIdValidator
  ) { }

  editCampaignTracking(data: any = {}, isNew?, context: any = null, targetName: string = null) {
    console.log("editCampaignTracking data : ", data);

    let countiesArray: Array<any> = [];
    for (let [key, value] of Object.entries(this.counties)) {
      countiesArray.push({ value: key, label: value['label'] })
    }

    const dialogRef: MatDialogRef<any> = this.dialog.open(FormModalComponent, {
      //maxWidth: '650px',
      minWidth: '320px',
      maxHeight: '100vh',
      data: {
        title: `Suivi de campagne de ${data.lastName ? data.lastName : data.campaignName ? data.campaignName.toLowerCase() : ''}`,
        subtitle: "",
        btnCancel: "Quit",
        btnOk: "Save",
        inputs: [
          {
            label: "Nom de la campagne",
            name: "campaignName",
            type: "input",
            placeholder: "Nom de la campagne",
            value: data.campaignName,
            validators: [Validators.required], error: "requis"
          },
          // { 
          //   label: "Image de la campagne", 
          //   name: "campaignImage", 
          //   type: "inputFile", 
          //   accept: "image/*",
          //   placeholder: "Image de la campagne", 
          //   value: data.campaignImage, 
          //   validators: [ Validators.required ] , error: "requis" 
          // },
          {
            label: "Image de la campagne",
            name: "campaignImage",
            type: "inputFilePreview",
            accept: "image/*",
            placeholder: "Image de la campagne",
            value: data.campaignImage ? [{ link: data.campaignImage, preview: data.campaignImage }] : null,
            validators: [Validators.required], error: "requis"
          },
          {
            label: "Département",
            name: "campaignCounty",
            type: "select",
            placeholder: "Département",
            value: data.campaignCounty,
            options: countiesArray,
            validators: [Validators.required], error: "requis"
          },
          {
            label: "Dates",
            name: "campaignDates",
            type: "dateRange",
            placeholder: "Campagne dates",
            value: data.campainDateBegin && data.campaignDateEnd ? { startDate: moment(data.campainDateBegin), endDate: moment(data.campaignDateEnd) } : null,
            validators: [Validators.required], error: "requis"
          },
          [
            {
              label: "Catalogue Id",
              name: "catalogId",
              type: "input",
              inputType: "number",
              placeholder: "Catalogue Id",
              value: data.catalogId,
              validators: [Validators.compose([Validators.required, Validators.maxLength(19), Validators.pattern('[0-9]*')]),
              [this.openCatalogIdValidator.checkCatalogId.bind(this.openCatalogIdValidator)]
              ], error: "requis",
              onValueChange: (form: FormGroup) => {

                function resetMetrics() {
                  form.controls.readingCount.setValue('');
                  form.controls.timeSpent.setValue('');
                  form.controls.readerReturnRate.setValue('');
                }

                let catalogId = form.controls.catalogId.value;
                // if catalogId is empty, reset the metrics
                if (!catalogId) {
                  resetMetrics();
                  return;
                }
                // check if startDate and endDate are defined else raise error to enter the dates before fetching metrics from Joomag
                if (!form.controls.campaignDates.value) {
                  this.displayErrorSnackbar("Veuillez entrer les dates de la campagne");
                  resetMetrics();
                  return;
                }
                let dateStart = form.controls.campaignDates.value.startDate.format('YYYY-MM-DD');
                let dateEnd = form.controls.campaignDates.value.endDate.format('YYYY-MM-DD');

                // fetch metrics from Joomag API through the backend server to avoid CORS error. If success, update the form with the metrics, else display error message and reset the metrics
                this.apiService.getJoomagStatistics(catalogId, dateStart, dateEnd).subscribe(result => {
                  // console.log("result: ", result);
                  if (result && result['data'] && result['error'] == false && result['data']['error'] == 0) {
                    form.controls.readingCount.setValue(result['data']['data']['unique_page_views']);
                    let timeSpent = result['data']['data']['avg-time-per-reader'];
                    let mins = Math.floor(timeSpent / 60);
                    let secs = timeSpent % 60;
                    form.controls.timeSpent.setValue(`${mins} min ${secs} secondes`);
                    let readerReturnRate = result['data']['data']['returning-readers'];
                    form.controls.readerReturnRate.setValue(readerReturnRate / 100);
                  } else {
                    this.displayErrorSnackbar("Une erreur s'est produite");
                    resetMetrics();
                  }
                }, error => {
                  this.displayErrorSnackbar(error);
                  resetMetrics();
                });
              }
            },
            {
              label: "Nbr total de lectures",
              name: "readingCount",
              type: "input",
              inputType: "number",
              placeholder: "Nbr total de lectures",
              value: data.readingCount,
              validators: [Validators.required], error: "requis"
            },
          ],
          [
            {
              label: "Budget global",
              name: "price",
              type: "input",
              inputType: "number",
              placeholder: "Budget global",
              value: data.price,
              validators: [Validators.required], error: "requis"
            },
            {
              label: "Temps moyen passé par lecteur",
              name: "timeSpent",
              type: "inputMask",
              //regex: "^(?:[0-9]\\d)-(?:[01]\\d|2[0-3])-(?:[0-5]\\d)$",
              placeholder: "",
              value: data.timeSpent,
              validators: [Validators.required], error: "requis"
              //validators: [ Validators.required, Validators.pattern(/^(?:[0-9]\d)-(?:[01]\d|2[0-3])-(?:[0-5]\d)$/) ] , error: "requis" 
            }
          ],
          [
            {
              label: "Taux de lecture(%)",
              name: "readRate",
              type: "input",
              inputType: "number",
              placeholder: "Taux de lecture",
              value: data.readRate,
              validators: [Validators.required], error: "requis"
            },
            {
              label: "Coût par lectures(€)",
              name: "costByReading",
              type: "input",
              placeholder: "Coût par lectures",
              value: data.costByReading,
              validators: [Validators.required], error: "requis"
            }
          ],
          [
            {
              label: "Lecteurs de retour (%)",
              name: "readerReturnRate",
              type: "input",
              inputType: "number",
              placeholder: "Lecteurs de retour",
              value: data.readerReturnRate,
              validators: [Validators.required], error: "requis"
            },
            {
              label: "Interstitiel (Impressions)",
              name: "dialogImpression",
              type: "input",
              placeholder: "Interstitiel",
              value: data.dialogImpression,
              validators: [Validators.required], error: "requis"
            }
          ],
          [
            {
              label: "Interstitiel (Clics)",
              name: "dialogClics",
              type: "input",
              inputType: "number",
              placeholder: "Interstitiel (Clics)",
              value: data.dialogClics,
              validators: [Validators.required], error: "requis"
            },
            {
              label: "Bannière (Impressions)",
              name: "bannerImpression",
              type: "input",
              inputType: "number",
              placeholder: "Bannière (Impressions)",
              value: data.bannerImpression,
              validators: [Validators.required], error: "requis"
            }
          ],
          [
            {
              label: "Bannière (Clics)",
              name: "bannerClics",
              type: "input",
              inputType: "number",
              placeholder: "Bannière (Clics)",
              value: data.bannerClics,
              validators: [Validators.required], error: "requis"
            }
          ],
          [
            {
              label: "Documents",
              name: "campaignDocuments",
              type: "inputFile",
              accept: "application/pdf",
              placeholder: "Documents",
              value: data.campaignDocuments ? data.campaignDocuments : null,
              validators: [Validators.required], error: "requis"
            },
          ]
        ]
      }
    });

    dialogRef.afterOpened().subscribe(() => {
      const formModalComponent: FormModalComponent = dialogRef.componentInstance;
      formModalComponent.form.get('catalogId').valueChanges.subscribe(() => {
        // console.log(formModalComponent.data.inputs);
        // data.inputs[4][0] refers to the input for catalogId. In case you add more inputs, you need to change the index accordingly.
        const onValueChangeFunction = formModalComponent.data.inputs[4][0].onValueChange;
        if (onValueChangeFunction) {
          onValueChangeFunction(formModalComponent.form);
        }
      });
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("result: ", result);

      if (result) {
        let campaignTracking = new FormData();

        if (result.campaignImage.files)
          campaignTracking.append("files", result.campaignImage.files[0]);
        else
          campaignTracking.append("files", result.campaignImage[0].file);

        campaignTracking.append("userId", data._id);

        campaignTracking.append("catalogId", result.catalogId);
        campaignTracking.append("campaignName", result.campaignName);
        campaignTracking.append("campaignCounty", result.campaignCounty);

        campaignTracking.append("dateBegin", result.campaignDates.startDate.toDate().toUTCString());
        campaignTracking.append("dateEnd", result.campaignDates.endDate.toDate().toUTCString());

        campaignTracking.append("readingCount", result.readingCount);
        campaignTracking.append("price", result.price);
        campaignTracking.append("timeSpent", result.timeSpent);
        campaignTracking.append("readRate", result.readRate);
        campaignTracking.append("costByReading", result.costByReading);

        campaignTracking.append("readerReturnRate", result.readerReturnRate);
        campaignTracking.append("dialogImpression", result.dialogImpression);
        campaignTracking.append("dialogClics", result.dialogClics);
        campaignTracking.append("bannerImpression", result.bannerImpression);
        campaignTracking.append("bannerClics", result.bannerClics);
        if (result.campaignDocuments.files)
          campaignTracking.append("campaignDocuments", result.campaignDocuments.files[0]);
        else
          campaignTracking.append("campaignDocuments", result.campaignDocuments[0].file);

        /*
                for(var pair of filesForm.entries()) {
                  console.log(pair[0]+ ', '+ pair[1]); 
                }
        */
        this.loader.open("Veuillez patienter");

        let apiFunctionToCall = isNew ? this.apiService.adminAddCampaignTracking(campaignTracking) : this.apiService.adminEditCampaignTracking(data._id, campaignTracking);
        apiFunctionToCall
          .subscribe(
            reponse => {
              if (reponse && reponse['error'] == false) {
                if (context && targetName && !isNew) {
                  this.updateItem(data._id, reponse['campaign'], context, targetName);
                }
                //this.updateItem(data._id, reponse['user'], context, targetName);
                this.snackBar.open(this.customText.snackBar_admin_campaign_tracking_added.text, "", {
                  duration: this.customText.snackBar_admin_campaign_tracking_added.duration,
                  verticalPosition: this.customText.snackBar_admin_campaign_tracking_added.verticalPosition,
                  horizontalPosition: this.customText.snackBar_admin_campaign_tracking_added.horizontalPosition
                });

              } else {
                this.displayErrorSnackbar("Une erreur s'est produite");
              }
            },
            error => {
              this.loader.close();
              this.displayErrorSnackbar(error);
            },
            () => {
              this.loader.close();
            }
          )

      }
    });

  }

  editUser(context: any, targetName, data: any = {}) {

    const dialogRef: MatDialogRef<any> = this.dialog.open(FormModalComponent, {
      //maxWidth: '650px',
      minWidth: '320px',
      data: {
        title: "Update utilisateur",
        subtitle: "",
        btnCancel: "Quit",
        btnOk: "Save",
        inputs: [
          [
            { label: "Nom", name: "lastName", type: "input", placeholder: "Nom", value: data.lastName, validators: [Validators.required], error: "requis" },
            { label: "Prénom", name: "firstName", type: "input", placeholder: "Prénom", value: data.firstName, validators: [Validators.required], error: "requis" }
          ],
          { label: "Email", name: "email", type: "input", placeholder: "Adresse email", value: data.email, validators: [Validators.required], error: "requis" },
          { label: "Entreprise", name: "company", type: "input", placeholder: "Entreprise", value: data.company, validators: [Validators.required], error: "requis" },
          { label: "Fonction", name: "position", type: "input", placeholder: "Fonction", value: data.position, validators: [Validators.required], error: "requis" },
          [
            { label: "N° de téléphone", name: "phone", type: "input", placeholder: "N° de téléphone", value: data.phone, validators: [Validators.required], error: "requis" },
            { label: "Utilisateur activé", name: "approved", type: "slider", placeholder: "Compte activé", value: data.approved, validators: [Validators.required], error: "requis" },
          ]
        ]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("result: ", result);
      if (result) {
        this.apiService.adminEditUser(data._id, result)
          .subscribe(
            reponse => {
              if (reponse && reponse['error'] == false) {
                this.updateItem(data._id, reponse['user'], context, targetName);
                this.snackBar.open(this.customText.snackBar_admin_user_edited.text, "", {
                  duration: this.customText.snackBar_admin_user_edited.duration,
                  verticalPosition: this.customText.snackBar_admin_user_edited.verticalPosition,
                  horizontalPosition: this.customText.snackBar_admin_user_edited.horizontalPosition
                });

              } else {
                this.displayErrorSnackbar("Error editing user");
              }
            },
            error => {
              this.displayErrorSnackbar(error);
            },
            () => {

            }
          )

      }
    });

  }

  editAssignResponsable(data: any = {}) {
    const dialogRef: MatDialogRef<any> = this.dialog.open(FormModalComponent, {
      //maxWidth: '650px',
      minWidth: '320px',
      data: {
        title: "Assigner un responsable",
        subtitle: "",
        btnCancel: "Quit",
        btnOk: "Save",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("result: ", result);
      if (result) {
        // REPLACE APISERVICE METHOD 
        // this.apiService.adminEditUser(data._id, result)
        //     .subscribe(
        //   reponse => {
        //     if (reponse && reponse['error'] == false) {
        //       // CHANGE CUSTOM TEXT 
        //       this.snackBar.open(this.customText.snackBar_admin_user_edited.text, "", {
        //         duration: this.customText.snackBar_admin_user_edited.duration,
        //         verticalPosition: this.customText.snackBar_admin_user_edited.verticalPosition,
        //         horizontalPosition: this.customText.snackBar_admin_user_edited.horizontalPosition
        //       });

        //     } else {
        //       this.displayErrorSnackbar("Error editing user");
        //     }
        //   },
        //   error => {
        //     this.displayErrorSnackbar(error);
        //   },
        //   () => {

        //   }
        // )

      }
    });
  }

  displayErrorSnackbar(message) {
    this.snackBar.open(message, "", {
      duration: this.customText.general_error.duration,
      verticalPosition: this.customText.general_error.verticalPosition,
      horizontalPosition: this.customText.general_error.horizontalPosition
    });
  }

  removeItem(row, itemsArray) {
    let i = itemsArray.indexOf(row);
    itemsArray.splice(i, 1);
    if (itemsArray)
      itemsArray = [...itemsArray]
  }

  updateItem(id, item, context, targetName) {
    context[targetName] = context[targetName].map(i => {
      if (i._id === id) {
        return Object.assign({}, i, item);
      }
      return i;
    })
  }


  ;

}
